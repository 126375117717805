import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Check, Delete, Edit, Guide, Support } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLinkAPI,
  postLinkAPI,
} from 'utils/@reduxjs/generateLink/settingAPI';
import { toast } from 'react-hot-toast';

export function Settings() {
  const dispatch = useDispatch();

  const [guideLink, setGuideLink] = React.useState('');
  const [supportLink, setSupportLink] = React.useState('');

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(fetchLinkAPI(setGuideLink, setSupportLink));
  };

  const settings = useSelector((state: any) => state.settings.links.response);
  const linkValues = useSelector((state: any) => state.settings.linkValues);

  // React.useEffect(() => {
  //   setGuideLink(linkValues.guideLink);
  //   setSupportLink(linkValues.supportLink);
  // }, [linkValues]);

  console.log({ settings, guideLink, supportLink });

  const handleGuideLink = () => {
    if (guideLink) {
      const data = {
        name: 'guide',
        link: guideLink,
      };
      dispatch(postLinkAPI(data));
    } else {
      toast.error('Field cannot be empty!');
    }
  };
  const handleSupportLink = () => {
    if (supportLink) {
      const data = {
        name: 'support',
        link: supportLink,
      };
      dispatch(postLinkAPI(data));
    } else {
      toast.error('Field cannot be empty!');
    }
  };
  console.log({ supportLink, guideLink });
  return (
    <>
      <Helmet>
        <title>One Clip - Admin</title>
        <meta name="description" content="One Clip" />
      </Helmet>
      <div className="mt-6">
        <h1 className="text-white font-bold text-xl mb-6">Settings</h1>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-start gap-8 w-full bg-white rounded-md p-6 shadow-dashboard-card-shadow">
            <div className="w-full">
              <div className="flex justify-start items-center gap-4 w-full border-b pb-4 mb-4 border-extra-light-blue text-dark-blue">
                <div className="w-12 h-12 rounded-full flex justify-center items-center bg-light-purple">
                  <Guide />
                </div>
                <div>
                  <p className="font-bold text-base mb-1">Guide Link</p>
                  <p className="text-sm opacity-50">
                    Select which link to use for user guides.
                  </p>
                </div>
              </div>

              <div className="flex justify-items-start">
                <div
                  className="flex flex-col text-dark-blue text-sm gap-2 font-bold"
                  style={{ flex: 1 }}
                >
                  <label htmlFor="detail">Guide link</label>
                  <input
                    type="text"
                    name="detail"
                    value={guideLink}
                    onChange={e => setGuideLink(e.target.value)}
                    id="detail"
                    style={{ width: '80%' }}
                    placeholder="www.guidelink.com"
                    className="bg-light-grey w-full sm:w-2/5 py-3 px-4 text-sm border font-normal border-extra-light-blue rounded-md outline-none disabled:opacity-4"
                  />
                </div>
                <div
                  className="flex items-center mt-6 gap-3"
                  style={{ flex: 1 }}
                >
                  <div className="cursor-pointer" onClick={handleGuideLink}>
                    <Check />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-start gap-8 w-full bg-white rounded-md p-6 shadow-dashboard-card-shadow">
            <div className="w-full">
              <div className="flex justify-start items-center gap-4 w-full border-b pb-4 mb-4 border-extra-light-blue text-dark-blue">
                <div className="w-12 h-12 rounded-full flex justify-center items-center bg-light-purple">
                  <Support />
                </div>
                <div>
                  <p className="font-bold text-base mb-1">Support Link</p>
                  <p className="text-sm opacity-50">
                    Select which link to use for user Support.
                  </p>
                </div>
              </div>
              <div className="flex justify-items-start">
                <div
                  className="flex flex-col text-dark-blue text-sm gap-2 font-bold"
                  style={{ flex: 1 }}
                >
                  <label htmlFor="detail">Support link</label>
                  <input
                    type="text"
                    name="detail"
                    value={supportLink}
                    onChange={e => setSupportLink(e.target.value)}
                    id="detail"
                    style={{ width: '80%' }}
                    placeholder="www.supportlink.com"
                    className="bg-light-grey w-full sm:w-2/5 py-3 px-4 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
                  />
                </div>
                <div
                  className="flex items-center mt-6 gap-3"
                  style={{ flex: 1 }}
                >
                  <div className="cursor-pointer" onClick={handleSupportLink}>
                    <Check />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
