import axios from 'axios';
import { dashboardActions } from './dashboardSlice';

export const fetchUserCountAPI = (filter?: string) => async dispatch => {

    try {
        const userStringify: any = localStorage.getItem('user');
        const auth = JSON.parse(userStringify);
        console.log({auth});
        const headers = {
            'Cache-Control': 's-maxage=1, stale-while-revalidate',
            'access-token': auth.token,
        };
        document.body.classList.add("loading-indicator");
        let url = `${process.env.REACT_APP_API_URL}/user/count?filter=${filter}`

        const response = await axios.get(url, { headers });
        document.body.classList.remove("loading-indicator");
        dispatch(dashboardActions.getUserCount(response.data));

    } catch (error) {
        // dispatch(fetchTodosFailure(error.message));
        document.body.classList.remove("loading-indicator");
        console.log(error);
    }
};

export const fetchUserPlanAPI = (filter?: string) => async dispatch => {

    try {
        const userStringify: any = localStorage.getItem('user');
        const auth = JSON.parse(userStringify);
        const headers = {
            'Cache-Control': 's-maxage=1, stale-while-revalidate',
            'access-token': auth.token,
        };
        document.body.classList.add("loading-indicator");
        let url = `${process.env.REACT_APP_API_URL}/user/count/plans?filter=${filter}`

        const response = await axios.get(url, { headers });
        document.body.classList.remove("loading-indicator");
        dispatch(dashboardActions.getUserCountPlans(response.data));

    } catch (error) {
        // dispatch(fetchTodosFailure(error.message));
        document.body.classList.remove("loading-indicator");
        console.log(error);
    }
};
export const fetchUserLocationAPI = (filter?: string) => async dispatch => {

    try {
        const userStringify: any = localStorage.getItem('user');
        const auth = JSON.parse(userStringify);
        const headers = {
            'Cache-Control': 's-maxage=1, stale-while-revalidate',
            'access-token': auth.token,
        };
        document.body.classList.add("loading-indicator");
        let url = `${process.env.REACT_APP_API_URL}/user/count/location?filter=${filter}`

        const response = await axios.get(url, { headers });
        document.body.classList.remove("loading-indicator");
        dispatch(dashboardActions.getUserCountLocation(response.data));

    } catch (error) {
        // dispatch(fetchTodosFailure(error.message));
        document.body.classList.remove("loading-indicator");
        console.log(error);
    }
};
export const fetchVideoCountAPI = (filter?: string) => async dispatch => {

    try {
        const userStringify: any = localStorage.getItem('user');
        const auth = JSON.parse(userStringify);
        const headers = {
            'Cache-Control': 's-maxage=1, stale-while-revalidate',
            'access-token': auth.token,
        };
        document.body.classList.add("loading-indicator");
        let url = `${process.env.REACT_APP_API_URL}/video/count?filter=${filter}`

        const response = await axios.get(url, { headers });
        document.body.classList.remove("loading-indicator");
        dispatch(dashboardActions.getVideoCount(response.data));

    } catch (error) {
        // dispatch(fetchTodosFailure(error.message));
        document.body.classList.remove("loading-indicator");
        console.log(error);
    }
};
export const fetchVideoTopUsersAPI = (filter?: string) => async dispatch => {

    try {
        const userStringify: any = localStorage.getItem('user');
        const auth = JSON.parse(userStringify);
        const headers = {
            'Cache-Control': 's-maxage=1, stale-while-revalidate',
            'access-token': auth.token,
        };
        document.body.classList.add("loading-indicator");
        let url = `${process.env.REACT_APP_API_URL}/video/topusers`

        const response = await axios.get(url, { headers });
        document.body.classList.remove("loading-indicator");
        dispatch(dashboardActions.getVideoTopUsers(response.data));

    } catch (error) {
        // dispatch(fetchTodosFailure(error.message));
        document.body.classList.remove("loading-indicator");
        console.log(error);
    }
};

export const fetchCustomdomainCountAPI = (filter?: string) => async dispatch => {

    try {
        const userStringify: any = localStorage.getItem('user');
        const auth = JSON.parse(userStringify);
        const headers = {
            'Cache-Control': 's-maxage=1, stale-while-revalidate',
            'access-token': auth.token,
        };
        document.body.classList.add("loading-indicator");
        let url = `${process.env.REACT_APP_API_URL}/customdomain/count?filter=${filter}`

        const response = await axios.get(url, { headers });
        document.body.classList.remove("loading-indicator");
        dispatch(dashboardActions.getCustomdomainCount(response.data));

    } catch (error) {
        // dispatch(fetchTodosFailure(error.message));
        document.body.classList.remove("loading-indicator");
        console.log(error);
    }
};