import axios from 'axios';
import { faqActions } from './FaqSlice';
import { toast } from 'react-hot-toast';

export const fetchFaqsAPI = (page?: any) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token':
        auth.token,
    };
    document.body.classList.add("loading-indicator");

    let url = `${process.env.REACT_APP_API_URL}/faq?page=${page}`;

    const response = await axios.get(url, { headers });
    document.body.classList.remove("loading-indicator");


    dispatch(faqActions.getFaq(response.data));
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    document.body.classList.remove("loading-indicator");

    console.log(error);
  }
};

export const createFaqsAPI = (data?: any) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token':
        auth.token,
    };
    document.body.classList.add("loading-indicator");

    toast.loading('Creating Faq');
    // console.log({ headers, data });
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/faq`,
      data: data,
      headers: headers,
    });
    toast.success('Faq created.');
    toast.dismiss();
    document.body.classList.remove("loading-indicator");

    dispatch(fetchFaqsAPI());
  } catch (error: any) {
    // dispatch(fetchTodosFailure(error.message));
    toast.dismiss();
    document.body.classList.remove("loading-indicator");

    toast.error(error.response.data.error);
    console.log(error.response.data);
  }
};
export const editFaqsAPI = (data?: any, id?: string) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token':
        auth.token,
    };
    document.body.classList.add("loading-indicator");

    toast.loading('Updating Faq');

    // console.log({ headers, data });
    await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/faq/${id}`,
      data: data,
      headers: headers,
    });
    toast.success('Faq created.');
    toast.dismiss();
    document.body.classList.remove("loading-indicator");

    dispatch(fetchFaqsAPI());
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    toast.dismiss();
    document.body.classList.remove("loading-indicator");

    console.log(error);
  }
};

export const deleteFaqsAPI = (id?: any) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token':
        auth.token,
    };
    document.body.classList.add("loading-indicator");

    toast.loading('Deleting Faq.');

    let url = `${process.env.REACT_APP_API_URL}/faq/${id}`;

    const response = await axios.delete(`${url}`, { headers });
    toast.dismiss();
    toast.success('Faq deleted successfully.');
    toast.dismiss();
    document.body.classList.remove("loading-indicator");

    dispatch(fetchFaqsAPI(1));
  } catch (error) {
    toast.dismiss();
    document.body.classList.remove("loading-indicator");

    // dispatch(fetchTodosFailure(error.message));
    console.log(error);
  }
};
