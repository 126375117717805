import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { AddRounded } from '@mui/icons-material';
import { SubscriptionCard } from 'app/components/SubscriptionCard';
import { SubscriptionCreateEditDialog } from 'app/components/SubscriptionCreateEditDialog';
import { ProductCard } from 'app/components/ProductCard';
import { ProductCreateEditDialog } from 'app/components/ProductCreateEditDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomDomainAPI,
  fetchSubscriptionAPI,
  fetchSubscriptionProductAPI,
} from 'utils/@reduxjs/subscription/SubscriptionApi';
import CustomDomainEditPopUp from 'app/components/CustomDomainEditPopUp';
import { CustomDomainCard } from 'app/components/CustomDomainCard';

export function Subscriptions() {
  const [subscriptions, setSubscriptions] = React.useState([]);
  const data = useSelector(
    (state: any) => state.subscription.subscription.response,
  );

  const dataProduct = useSelector(
    (state: any) => state.subscription.subProducts.response,
  );

  const dataCustomDomain = useSelector(
    (state: any) => state.subscription.customDomain.response,
  );

  console.log('dataProduct', dataProduct);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchSubscriptionAPI(1));
    dispatch(fetchSubscriptionProductAPI(1));
    dispatch(fetchCustomDomainAPI(1));
    console.log({ data }, 'LLLLLLL');
  }, []);

  React.useEffect(() => {
    // dispatch(fetchSubscriptionAPI(1))
    console.log({ data }, 'LLLLLLL');
    setSubscriptions(data);
  }, [data]);
  const [products, setProducts] = React.useState([]);

  const [openModal, setOpenModal] = React.useState<boolean | false>(false);
  const [openProductModal, setOpenProductModal] = React.useState<
    boolean | false
  >(false);
  const [openCustomDomain, setopenCustomDomainModal] = React.useState<
    boolean | false
  >(false);
  const [activeTab, setActiveTab] = React.useState('subscription');

  const handleClickOpenProductModal = () => {
    setOpenProductModal(true);
  };

  const handleCloseProductModal = () => {
    setOpenProductModal(false);
  };
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  console.log({ dataProduct });
  return (
    <>
      <Helmet>
        <title>One Clip - Admin</title>
        <meta name="description" content="One Clip" />
      </Helmet>
      <div className="mt-6">
        <div className="flex justify-between items-center mb-6">
          {/* <h1 className="text-white font-bold text-xl">Subscription</h1> */}
          <div className=" flex items-center justify-start gap-6">
            <span
              className={` text-xl cursor-pointer border-b-2 border-transparent -m-[2px] transition-all font-bold ${activeTab === 'subscription'
                ? 'text-white !border-white'
                : 'text-dark-blue'
                } `}
              onClick={() => setActiveTab('subscription')}
            >
              Subscription
            </span>
            <span
              className={` text-xl cursor-pointer border-b-2 border-transparent -m-[2px] transition-all font-bold ${activeTab === 'products'
                ? 'text-white !border-white'
                : 'text-dark-blue'
                } `}
              onClick={() => setActiveTab('products')}
            >
              Products
            </span>

            <span
              className={` text-xl cursor-pointer border-b-2 border-transparent -m-[2px] transition-all font-bold ${activeTab === 'customDomain'
                ? 'text-white !border-white'
                : 'text-dark-blue'
                } `}
              onClick={() => setActiveTab('customDomain')}
            >
              Custom Domain
            </span>
          </div>
          {activeTab !== 'customDomain' && <div
            className="bg-white border-2 flex justify-start items-center gap-2 border-light-purple text-light-purple font-bold py-3 px-5 rounded-md cursor-pointer text-sm"
            onClick={
              activeTab === 'subscription'
                ? handleClickOpenModal
                : handleClickOpenProductModal
            }
          >
            <AddRounded width={10} />
            Create {activeTab === 'subscription' ? 'Subscription' : 'Product'}
          </div>}
        </div>
        <div className="flex flex-col gap-4">
          {activeTab === 'subscription' ? (
            subscriptions?.length === 0 ? (
              <div>
                <div className="flex items-center justify-center flex-col w-full h-full">
                  <div className="text-dark-blue font-bold text-lg mt-4 text-center w-[40%]">
                    No Subscription Created
                    <p className="text-xs opacity-50 mt-4">
                      It looks like you haven’t created a Subscription yet. Why not
                      Create one? <br />
                      Create a Subscription in a few simple steps.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-4">
                {subscriptions?.map((subscription: any) => (
                  <SubscriptionCard
                    key={subscription?._id}
                    subscription={subscription}
                    setSubscriptions={setSubscriptions}
                  />

                ))}
              </div>
            )
          ) : activeTab === 'products' ? dataProduct?.length === 0 ? (
            <div>
              <div className="flex items-center justify-center flex-col w-full h-full">
                <div className="text-dark-blue font-bold text-lg mt-4 text-center w-[40%]">
                  No Product Created
                  <p className="text-xs opacity-50 mt-4">
                    It looks like you haven’t created a Product yet. Why not
                    Create one? <br />
                    Create a Product in a few simple steps.
                  </p>
                  {/* <button
                className="bg-[#6585E8] text-white rounded-lg px-4 py-3 mt-4 text-sm inline-flex justify-center items-center gap-2"
                onClick={() => navigate('/create-video')}
              >
                Create First Video
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-4">
              {dataProduct?.map(product => (
                <ProductCard
                  key={product._id}
                  product={product}
                  setProducts={setProducts}
                />
              ))}
            </div>
          ) : <>
            <div className="grid grid-cols-3 gap-4">
              {dataCustomDomain?.map(product => (
                <CustomDomainCard
                  key={product._id}
                  subscription={product}
                  setSubscriptions={setProducts}
                />
              ))}
            </div>
          </>}
        </div>
      </div>

      <SubscriptionCreateEditDialog
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setSubscriptions={setSubscriptions}
        edit={false}
        subscription={null}
      />
      <ProductCreateEditDialog
        openModal={openProductModal}
        handleCloseModal={handleCloseProductModal}
        setProducts={setProducts}
        edit={false}
        product={null}
      />
    </>
  );
}
