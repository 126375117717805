import { createSlice } from "@reduxjs/toolkit";

type faqState = { faq: any, }
const initialState: faqState = {
    faq: {},

}


export const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        getFaq: (state, action) => {
            state.faq = { ...state.faq, ...action.payload };
        }
    },
});

export default faqSlice.reducer;

export const { reducer: faqReducers, actions: faqActions } = faqSlice;

