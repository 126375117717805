import React from 'react';
import { Geography } from 'assets/icons';

export default function TotalUsersCard({ ...props }) {
  console.log(props.users, '::::::');
  return (
    <div
      key={props.id}
      className={`bg-white rounded-lg w-full mt-4 shadow-dashboard-card-shadow`}
    >
      <div className="flex justify-center items-center bg-[#DDEBFB] p-6 rounded-t-lg ">
        <div className="font-bold flex items-start gap-2 text-sm">
          <Geography />
          No of users - Geography
        </div>
      </div>
      <div className="flex justify-between items-center flex-col gap-4 px-4 ">
        {props.users?.map((user: any) => (
          <div
            className={`w-full first:pt-4 pb-4 px-4 border-b last:border-b-0 border-extra-light-blue flex justify-between items-center text-dark-blue`}
          >
            <div className="flex justify-start items-center gap-4">
              {/* <img
                className="rounded-full w-8 h-8"
                src={user?.img}
                alt="country"
              /> */}
              <p className="font-bold text-sm capitalize">{user?._id || "null"}</p>
            </div>

            <p className="opacity-50">{user?.count}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
