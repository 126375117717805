import React, { useState } from 'react';
import { Delete, Edit } from 'assets/icons';
import { FAQsCreateEditDialog } from '../FAQsCreateEditDialog';
import { useDispatch } from 'react-redux';
import { deleteFaqsAPI } from 'utils/@reduxjs/FAQ/FaqApi';
import ConfirmDialog from '../ConfirmationPopUp';

export function FAQsCard({ faq }) {
  const [openModal, setOpenModal] = useState<boolean | false>(false);
  const [editFields, setEditFields] = useState<boolean | false>(false);

  const handleClickOpenModal = data => {
    setOpenModal(true);
    setEditFields(data);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const dispatch = useDispatch();

  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false)
  const handleDelete = id => {
    console.log({ id });
    dispatch(deleteFaqsAPI(id));
    // setFaqs(faqs => faqs.filter(sub => sub.id !== faq.id));
    setShowConfirmationPopUp(false)
  };
  return (
    <>
      <div id={faq._id} className="w-full px-6 first:pt-6 ">
        <div className="flex justify-between items-center gap-4 w-full border-b pb-4 mb-4 border-extra-light-blue text-dark-blue">
          <div>
            <p className="font-bold text-base mb-1">{faq.title}</p>
            <span dangerouslySetInnerHTML={{ __html: faq.description }} />
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row gap-4">
            <div
              className="bg-[#f0f3fd] text-light-purple rounded-md w-8 h-8 flex items-center justify-center cursor-pointer"
              onClick={() => handleClickOpenModal(faq)}
            >
              <Edit />
            </div>
            <div
              className="bg-[#f9eafc] text-light-purple rounded-md w-8 h-8 flex items-center justify-center cursor-pointer"
              onClick={() => setShowConfirmationPopUp(true)}
            >
              <Delete />
            </div>
          </div>
        </div>
        <FAQsCreateEditDialog
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          edit={true}
          // setFaqs={setFaqs}
          faq={faq}
        />
      </div>

      {
        showConfirmationPopUp && <ConfirmDialog text={'This FAQ will be permanetly deleted.'} confirmOnClick={() => handleDelete(faq._id)} cancelOnclick={() => setShowConfirmationPopUp(false)} />
      }
    </>
  );
}
