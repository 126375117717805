import React, { useState } from 'react';
import { Delete, Edit } from 'assets/icons';
import { ProductCreateEditDialog } from '../ProductCreateEditDialog';
import { useDispatch } from 'react-redux';
import { deleteSubscriptionApi, deleteSubscriptionApiProduct } from 'utils/@reduxjs/subscription/SubscriptionApi';
// import { deleteSubscriptionApi } from 'utils/@reduxjs/product/SubscriptionApi';

export function ProductCard({ product, setProducts }) {
  const [openModal, setOpenModal] = useState<boolean | false>(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteSubscriptionApiProduct(product._id));
  };
  console.log("product.product", product);
  return (
    <div
      key={product._id}
      className="flex justify-between items-start gap-8 w-full bg-white rounded-md p-6 shadow-dashboard-card-shadow"
    >
      <div className="w-full h-full ">
        <div className="flex justify-between items-center gap-4 w-full pb-4 border-extra-light-blue text-dark-blue">
          <div>
            <p className="font-bold text-base mb-1">$ {product.price}</p>
            <p className="text-sm opacity-50">
              No. of Videos Available : {product.noOfVideos}
            </p>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row gap-4">
            <div
              className="bg-[#f0f3fd] text-light-purple rounded-md w-8 h-8 flex items-center justify-center cursor-pointer"
              onClick={handleClickOpenModal}
            >
              <Edit />
            </div>
            <div
              className="bg-[#f9eafc] text-light-purple rounded-md w-8 h-8 flex items-center justify-center cursor-pointer"
              onClick={handleDelete}
            >
              <Delete />
            </div>
          </div>
        </div>
      </div>
      <ProductCreateEditDialog
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        edit={true}
        setProducts={setProducts}
        product={product}
      />
    </div>
  );
}
