import { combineReducers } from 'redux';
import { faqReducers } from 'utils/@reduxjs/FAQ/FaqSlice';
import { usersReducers } from 'utils/@reduxjs/User/UserSlice';
import { authReducers } from 'utils/@reduxjs/auth/authSlice';
import { dashboardReducers } from 'utils/@reduxjs/dashboard/dashboardSlice';

import { InjectedReducersType } from 'utils/types/injector-typings';
import { subscriptionReducers } from 'utils/@reduxjs/subscription/SubscriptionSlice';
import { settingsReducers } from 'utils/@reduxjs/generateLink/settingSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    ...injectedReducers,
    admin: authReducers,
    dashboard: dashboardReducers,
    users: usersReducers,
    faq: faqReducers,
    subscription: subscriptionReducers,
    settings:settingsReducers
  });
}
