import axios from 'axios';
import { settingsActions } from './settingSlice';
import { toast } from 'react-hot-toast';

export const fetchLinkAPI =
  (setGuideLink?: any, setSupportLink?: any) => async dispatch => {
    try {
      const userStringify: any = localStorage.getItem('user');
      const auth = JSON.parse(userStringify);
      const headers = {
        'Cache-Control': 's-maxage=1, stale-while-revalidate',
        'access-token': auth.token,
      };
      document.body.classList.add('loading-indicator');

      let url = `${process.env.REACT_APP_API_URL}/link`;

      const response = await axios.get(url, { headers });
      document.body.classList.remove('loading-indicator');
      console.log({ redux: response.data.response });
      let data = response.data.response;
      if (data.length > 0) {
        const guideLink: any = data?.find(ele => ele.name == 'guide');
        if(guideLink) {
          setGuideLink(guideLink.link)
        }

        const supportLink: any = data?.find(ele => ele.name == 'support');
        if(supportLink){
          setSupportLink(supportLink.link)
        }
        dispatch(
          settingsActions.setLinkValues({
            guideLink: guideLink.link,
            supportLink: supportLink.link,
          }),
        );
      }
      dispatch(settingsActions.getLink(response.data));
    } catch (error) {
      // dispatch(fetchTodosFailure(error.message));
      document.body.classList.remove('loading-indicator');

      console.log(error);
    }
  };

export const postLinkAPI = (data: any) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };

    toast.loading('Creating Link');
    let url = `${process.env.REACT_APP_API_URL}/link`;

    const response = await axios.post(url, data, { headers });
    toast.dismiss();
    toast.success('Link Created');
    dispatch(settingsActions.getLink(response.data));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.error);
    // dispatch(fetchTodosFailure(error.message));

    console.log(error);
  }
};

export const putLinkAPI = (id: string, data: any) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };
    document.body.classList.add('loading-indicator');

    let url = `${process.env.REACT_APP_API_URL}/link/${id}`;

    const response = await axios.put(url, data, { headers });
    document.body.classList.remove('loading-indicator');

    dispatch(settingsActions.getLink(response.data));
  } catch (error: any) {
    document.body.classList.remove('loading-indicator');
    toast.error(error?.response?.data?.error);
  }
};
