import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FormControl, MenuItem, Select, FormControlLabel } from '@mui/material';
import { UserlistTable } from 'app/components/UserList/UserlistTable';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersAPI } from 'utils/@reduxjs/User/UserApi';
import { fetchSubscriptionAPI } from 'utils/@reduxjs/subscription/SubscriptionApi';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import { DateRangePicker } from 'react-date-range';
// import moment from 'moment';
import { toast } from 'react-hot-toast';
import moment from 'moment-timezone';

export function Users() {
  const [checked, setChecked] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('active');
  const [userSearch, setUserSearch] = React.useState('');
  const [userPlan, setUserPlan] = React.useState('select');
  const [customDomain, setCustomDomain] = React.useState('select');
  const [chooseDate, setChooseDate] = React.useState('select');
  const [noOfItems, setNoOfItems] = React.useState<any>(10);
  const [page, setPage] = React.useState(1);

  console.log('noOfItems', noOfItems);

  const dispatch = useDispatch();
  console.log({ activeTab }, 'activeTab');
  // React.useEffect(() => {
  //   dispatch(fetchUsersAPI(page, activeTab));
  //
  // }, [activeTab, page]);

  const plan = useSelector(
    (state: any) => state.subscription.subscription.response,
  );

  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
  };
  const [selectedStartDate, setSelectedStartDate] = React.useState<any>(null);
  const [selectedStartDateSend, setSelectedStartDateSend] =
    React.useState<any>(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState<any>(null);
  const [selectedEndDateSend, setSelectedEndDateSend] =
    React.useState<any>(null);

  React.useEffect(() => {
    // dispatch(fetchSubscriptionAPI(page));
    if (noOfItems)
      console.log('noOfItemsnoOfItems', noOfItems);

    dispatch(
      fetchUsersAPI(
        {
          page,
          activeTab,
          planId: userPlan,
          checked: JSON.stringify(checked),
          userSearch,
          noOfItems,
          selectedStartDateSend,
          selectedEndDateSend,
        }),
    );
  }, [userPlan, checked, userSearch, page, activeTab, noOfItems, dispatch, selectedStartDateSend,
    selectedEndDateSend,]);

  // Triggers only on tab chnage to return to first page
  React.useEffect(() => {
    // dispatch(fetchSubscriptionAPI(page));
    // dispatch(
    //   fetchUsersAPI(
    //     page,
    //     activeTab,
    //     userPlan,
    //     JSON.stringify(checked),
    //     userSearch,
    //     noOfItems,
    //   ),
    // );
    setPage(1)
  }, [activeTab]);
  console.log('selectedStartDate', selectedStartDate);



  // React.useEffect(() => {
  //   if (!selectedStartDate && selectedEndDateSend) {
  //     toast.error('Select Start date');
  //   } else {
  //     if (noOfItems)
  //       console.log('noOfItems', noOfItems);

  //     dispatch(
  //       fetchUsersAPI(
  //         page,
  //         activeTab,
  //         userPlan,
  //         JSON.stringify(checked),
  //         userSearch,
  //         selectedStartDateSend,
  //         selectedEndDateSend,
  //         noOfItems,
  //       ),
  //     );
  //   }
  // }, [selectedEndDateSend, selectedStartDate, noOfItems]);

  const handleStartDateChange = (date: any | null) => {
    const formattedDate = moment(date?.$d).format('YYYY-MM-DD');
    setSelectedStartDate(date);
    setSelectedStartDateSend(formattedDate);
  };

  const handleEndDateChange = (date: any | null) => {
    console.log(typeof date, 'LLAALLAa');
    // let stringify = JSON.stringify(date)
    // console.log({stringify});
    // let newDate = new Date(stringify)
    if (date) {
      // const formattedDate = dayjs(selectedStartDate)?.format();
      const formattedDate = moment(date?.$d).format('YYYY-MM-DD');
      setSelectedEndDate(date);
      setSelectedEndDateSend(formattedDate);
      console.log({ formattedDate });
    }
  };

  console.log({
    selectedStartDate,
    selectedEndDate,
    selectedStartDateSend,
    selectedEndDateSend,
  });

  console.log('selectedStartDate', selectedStartDate);

  return (
    <>
      <Helmet>
        <title>One Clip - Admin</title>
        <meta name="description" content="One Clip" />
      </Helmet>
      <div className="mt-4">
        <h1 className="text-white font-bold text-xl mb-4">Users list</h1>
        <div className="w-full bg-white rounded-md">
          <div className="border-b-2 border-light-grey flex items-center justify-start gap-6 pl-10 pt-4 ">
            <span
              className={` text-sm cursor-pointer py-2 border-b-2 border-transparent -m-[2px] transition-all font-bold ${activeTab === 'active'
                ? 'text-pink !border-pink'
                : 'text-dark-blue'
                } `}
              onClick={() => setActiveTab('active')}
            >
              Active
            </span>
            <span
              className={` text-sm cursor-pointer py-2 border-b-2 border-transparent -m-[2px] transition-all font-bold ${activeTab === 'suspended'
                ? 'text-pink !border-pink'
                : 'text-dark-blue'
                } `}
              onClick={() => setActiveTab('suspended')}
            >
              Suspended
            </span>
          </div>
          <div className="p-4">
            <div className="flex  justify-between items-center mb-4 flex-col sm:flex-row">
              <div className="flex justify-start items-center gap-2 flex-wrap">
                <div className="relative w-full sm:w-auto">
                  <svg
                    className="absolute left-3 top-4"
                    width="16"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1507 14.3767C9.51077 17.2874 4.43801 16.4589 1.78999 13.1399C-0.783412 9.91449 -0.561809 5.38551 2.32408 2.41873C5.13929 -0.475129 9.73517 -0.819542 12.9509 1.64127C14.7451 3.01444 15.8127 4.83355 16.0938 7.07784C16.3732 9.3126 15.7757 11.323 14.358 13.1606C14.432 13.2083 14.514 13.2431 14.5723 13.3014C15.6113 14.3352 16.6464 15.3729 17.6843 16.4079C17.948 16.6709 18.068 16.9744 17.9642 17.3424C17.7886 17.9644 17.0548 18.2028 16.5465 17.8046C16.4803 17.753 16.4214 17.6924 16.362 17.6329C15.3347 16.607 14.3075 15.581 13.282 14.5534C13.2331 14.5052 13.1984 14.4418 13.1507 14.3767ZM14.367 8.08584C14.381 4.62432 11.5849 1.81516 8.10877 1.79777C4.6282 1.77982 1.81075 4.57047 1.7956 8.05106C1.78045 11.5339 4.58724 14.3576 8.07343 14.3672C11.5405 14.3762 14.3529 11.5692 14.367 8.08584Z"
                      fill="#262C4C"
                    />
                  </svg>

                  <input
                    type="text"
                    name="search"
                    id="search"
                    value={userSearch}
                    onChange={e => setUserSearch(e.target.value)}
                    placeholder="Search..."
                    className="bg-transparent sm:w-60 w-full  py-3 pl-9 text-sm border border-extra-light-blue rounded-md outline-none"
                  />
                </div>
                <FormControl
                  sx={{ m: 1 }}
                  variant="standard"
                  className="w-[48%] sm:w-auto !m-0"
                >
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    disableUnderline
                    value={userPlan}
                    onChange={e => {
                      setUserPlan(e.target.value);
                    }}
                    IconComponent={KeyboardArrowDown}
                    className="sm:!w-40 !w-full"
                    sx={{
                      background: 'transparent',
                      border: '1px solid #262C4C',
                      borderRadius: '6px',
                      opacity: '0.5',
                      fontSize: '14px',
                      padding: '5px 10px 5px 16px',
                      '.MuiSvgIcon-root': {
                        right: '5px',
                      },
                      '.MuiSelect-select': {
                        background: 'transparent !important',
                        color: '#262C4C99',
                        fontWeight: 700,
                      },
                    }}
                  >
                    <MenuItem value="select">Select Plans</MenuItem>
                    {plan &&
                      plan.map(ele => (
                        <MenuItem value={ele._id}>{ele.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-[45%] sm:w-44 !m-0"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    disableFuture
                    // minDate={
                    //   selectedStartDate
                    //     ? dayjs(selectedStartDate)?.startOf('day').toDate()
                    //     : undefined
                    // } // Set the minimum date to the start of the selected start date
                    sx={{
                      '.MuiInputBase-root': {
                        background: 'transparent',
                        border: '1px solid #262C4C',
                        borderRadius: '6px',
                        fontSize: '14px',
                        opacity: '0.5',
                        padding: '0px 15px',
                        height: '42px',
                        // width: '180px',
                        fontWeight: 700,
                      },
                      '.MuiInputBase-input': {
                        padding: '0',
                      },
                    }}
                  />
                </LocalizationProvider>
                to
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={selectedEndDate}
                    // onChange={handleEndDateChange}
                    onChange={handleEndDateChange}
                    // minDate={selectedStartDate}
                    className="w-[45%] sm:w-44 !m-0"
                    minDate={selectedStartDate} // Set the minimum date to the start of the selected start date
                    // maxDate={
                    //   selectedStartDate
                    //     ? dayjs(selectedStartDate)?.endOf('day').toDate()
                    //     : undefined
                    // } // Set the maximum date to the end of the selected start date
                    sx={{
                      '.MuiInputBase-root': {
                        background: 'transparent',
                        border: '1px solid #262C4C',
                        borderRadius: '6px',
                        fontSize: '14px',
                        opacity: '0.5',
                        padding: '0px 15px',
                        height: '42px',
                        // width: '180px',
                        fontWeight: 700,
                      },
                      '.MuiInputBase-input': {
                        padding: '0',
                      },
                    }}
                  />
                </LocalizationProvider>
                <FormControl
                  sx={{ m: 1 }}
                  variant="standard"
                  className="w-[48%] sm:!hidden !m-0"
                >
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    disableUnderline
                    value={noOfItems}
                    onChange={e => setNoOfItems(e.target.value)}
                    IconComponent={KeyboardArrowDown}
                    className="sm:!w-44 !w-full"
                    sx={{
                      background: 'transparent',
                      border: '1px solid #262C4C',
                      borderRadius: '6px',
                      width: '175px',
                      fontSize: '14px',
                      opacity: '0.5',
                      padding: '5px 10px 5px 16px',
                      '.MuiSvgIcon-root': {
                        right: '5px',
                      },
                      '.MuiSelect-select': {
                        background: 'transparent !important',
                        color: '#262C4C99',
                        fontWeight: 700,
                      },
                    }}
                  >
                    <MenuItem value={'1'}>1 to 5</MenuItem>
                    <MenuItem value={'2'}>1 to 10</MenuItem>
                    <MenuItem value={'3'}>1 to 20</MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <FormControlLabel
                    labelPlacement="start"
                    sx={{
                      background: 'transparent',
                      border: '1px solid #262C4C',
                      borderRadius: '6px',
                      width: '175px',
                      fontSize: '14px',
                      opacity: '0.5',
                      // padding: '5px 10px 5px 16px',
                      paddingLeft: '6px',
                      '.MuiTypography-root': {
                        right: '5px',
                      },
                      fontWeight: 700,
                      '.MuiFormControlLabel-label': {
                        background: 'transparent !important',
                        color: '#262C4C99',
                        fontWeight: 700,
                      },
                    }}
                    // variant="standard"
                    className="w-[48%] sm:w-auto !m-0"
                    label="Custom Domain"
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                      />
                    }
                  />
                </div>
              </div>
              <div className="sm:flex hidden">
                <FormControl sx={{ m: 1 }} variant="standard">
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    disableUnderline
                    value={noOfItems}
                    onChange={e => setNoOfItems(e.target.value)}
                    IconComponent={KeyboardArrowDown}
                    sx={{
                      background: 'transparent',
                      border: '1px solid #262C4C',
                      borderRadius: '6px',
                      width: '175px',
                      fontSize: '14px',
                      opacity: '0.5',
                      padding: '5px 10px 5px 16px',
                      '.MuiSvgIcon-root': {
                        right: '5px',
                      },
                      '.MuiSelect-select': {
                        background: 'transparent !important',
                        color: '#262C4C99',
                        fontWeight: 700,
                      },
                    }}
                  >
                    <MenuItem value={5}>1 to 5</MenuItem>
                    <MenuItem value={10}>1 to 10</MenuItem>
                    <MenuItem value={20}>1 to 20</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {userPlan?.length === 0 ? (
              <div>
                <div className="flex items-center justify-center flex-col w-full h-full">
                  <div className="text-dark-blue font-bold text-lg mt-4 text-center w-[40%]">
                    No User Found
                    {/* <p className="text-xs opacity-50 mt-4">
                      It looks like you haven’t created a Subscription yet. Why
                      not Create one? <br />
                      Create a Subscription in a few simple steps.
                    </p> */}
                    {/* <button
                className="bg-[#6585E8] text-white rounded-lg px-4 py-3 mt-4 text-sm inline-flex justify-center items-center gap-2"
                onClick={() => navigate('/create-video')}
              >
                Create First Video
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button> */}
                  </div>
                </div>
              </div>
            ) : (
              <UserlistTable
                activeTab={activeTab}
                userPlan={userPlan}
                page={page}
                setPage={setPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
