import { createSlice } from '@reduxjs/toolkit';

type faqState = { links: any; linkValues: any };
const initialState: faqState = {
  links: {},
  linkValues: {
    guideLink: '',
    supportLink: '',
  },
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getLink: (state, action) => {
      state.links = { ...state.links, ...action.payload };
    },
    setLinkValues: (state, action) => {
      state.linkValues = { ...state.linkValues, ...action.payload };
    },
  },
});

export default settings.reducer;

export const { reducer: settingsReducers, actions: settingsActions } = settings;
