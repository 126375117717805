import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'admin',
    initialState: {
        user: null,
    },
    reducers: {
        setToken: (state, action) => {
            state.user = action.payload;
        },
        clearToken: (state) => {
            state.user = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('persist/REHYDRATE', (state, action: any) => {
            console.log({action});
            // Check if the rehydration action payload contains the "admin" state
            if (action.payload && action.payload.admin) {
                state.user = action.payload.admin.user;
            }
        });
    },
});

export default authSlice.reducer;

export const { reducer: authReducers, actions: authActions } = authSlice;

