import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import user2big from 'assets/user2big.png';
import { TransactionsTable } from 'app/components/UsersDetails/TransactionsTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSingleUserAPI,
  fetchTransactionsAPI,
  unlockFeatureSingleUserAPI,
  userStatusAPI,
} from 'utils/@reduxjs/User/UserApi';
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ArrowLeft } from '@mui/icons-material';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

export function UsersDetails() {
  const { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchSingleUserAPI(id));
    dispatch(fetchTransactionsAPI(id));
  }, [id]);
  const data = useSelector((state: any) => state.users.singleUser.response);
  console.log({ data });

  const handleStatus = () => {
    console.log('its being called 2');

    let status = data && data[0]?.status === 'active' ? false : true;
    data && dispatch(userStatusAPI(id, { status }));
  };

  const navigate = useNavigate()


  const [dbgAIStatus, setDbgAIStatus] = React.useState(data && data[0]?.featuresAvailable?.aiDBGCampaign)
  const [trainignStatus, setTrainignStatus] = React.useState(data && data[0]?.featuresAvailable?.training)


  React.useEffect(() => {
    if (data) {

      setDbgAIStatus(data[0]?.featuresAvailable?.aiDBGCampaign)
      setTrainignStatus(data[0]?.featuresAvailable?.training)
    }
  }, [data])
  const handleDBG_AIChange = async () => {

    setDbgAIStatus(!dbgAIStatus)
    dispatch(unlockFeatureSingleUserAPI({
      userId: id, features: {
        training: data[0]?.featuresAvailable?.training,
        aiDBGCampaign: !dbgAIStatus
      }
    }, id))
  }

  const handleTrainingChange = async () => {

    setTrainignStatus(!trainignStatus)
    dispatch(unlockFeatureSingleUserAPI({
      userId: id, features: {
        aiDBGCampaign: data[0]?.featuresAvailable?.aiDBGCampaign,
        training: !trainignStatus

      }
    }, id))

  }
  return (
    <>
      <Helmet>
        <title>One Clip - Admin</title>
        <meta name="description" content="One Clip" />
      </Helmet>
      <div className="mt-6">
        <div
          className="flex justify-between items-center mb-6"
        >
          <div className='flex items-center gap-4'>
            <button onClick={() => navigate('/users')} className='px-4 text-sm flex items-center gap-2 py-2 border-2 text-white font-semibold'> <ArrowLeft /> Go Back</button>
            <h1 className="text-white font-bold text-xl">Users Details</h1>

          </div>
          <div className='flex items-center gap-2'>

            <div onClick={handleStatus} className="bg-white border-2 border-pink text-pink font-bold py-3 px-6 rounded-md cursor-pointer text-sm">
              {data && data[0]?.status === 'active'
                ? 'Suspend User'
                : 'Active User'}
            </div>

            <div className="bg-white border-2 border-pink text-pink font-bold px-3 py-1 rounded-md cursor-pointer text-sm">

              <FormControlLabel
                control={
                  <Switch
                    checked={dbgAIStatus}
                    onChange={handleDBG_AIChange}
                    // defaultChecked={subscription.deleted}
                    name={'DBG_AI'}
                  />
                }
                label={'DBG+'}
              />

            </div>

            <div className="bg-white border-2 border-pink text-pink font-bold px-3 py-1 rounded-md cursor-pointer text-sm">

              <FormControlLabel
                control={
                  <Switch
                    checked={trainignStatus}
                    onChange={handleTrainingChange}
                    // defaultChecked={subscription.deleted}
                    name={'trainign'}
                  />
                }
                label={'Avatar Training'}
              />

            </div>
          </div>
        </div>
        <div className="flex justify-between items-start gap-8 w-full bg-white rounded-md p-6">
          <div className="flex justify-start sm:items-center items-center gap-8 w-full sm:h-32 h-full sm:flex-row flex-col ">
            <div className="rounded-md w-52 ">
              {data && data[0]?.profilePic ? (
                <img
                  src={data && data[0]?.profilePic}
                  alt="campaign user name"
                  className="rounded-full"
                  height={"100px"}
                  width={"100px"}
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <AccountCircleIcon style={{ fontSize: '5rem' }} />
              )}
            </div>
            <div className="w-full flex sm:items-center items-[initial] sm:justify-start justify-between flex-wrap sm:flex-nowrap h-full flex-row ">
              <div className="sm:w-full w-1/2 flex flex-col justify-between items-start h-full py-2 gap-2 ">
                <div className="text-dark-blue sm:text-base text-sm font-bold">
                  <span className="font-normal opacity-50 sm:text-sm test-xs">
                    User Name
                  </span>
                  <p className="mt-1">{data && data[0]?.name}</p>
                </div>
                <div className="text-dark-blue sm:text-base text-sm font-bold">
                  <span className="font-normal opacity-50 sm:text-sm test-xs">
                    Start Date
                  </span>
                  <p className="mt-1">
                    {moment(data && data[0]?.createdAt).format(
                      'D MMM YYYY',
                    )}
                  </p>
                </div>
              </div>
              <div className="sm:w-full w-1/2 flex flex-col justify-between items-start h-full py-2 gap-2 ">
                <div className="text-dark-blue sm:text-base text-sm font-bold">
                  <span className="font-normal opacity-50 sm:text-sm test-xs">
                    User plan
                  </span>
                  <p className="sm:text-sm test-xs capitalize py-1.5 px-4 mt-1 rounded-3xl text-[#C131DE] bg-[#C131DE26] ">
                    {data && data[0]?.product[0]?.name}
                  </p>
                </div>
                <div className="text-dark-blue sm:text-base text-sm font-bold">
                  <span className="font-normal opacity-50 sm:text-sm test-xs">
                    Total Video Created
                  </span>
                  <p className="flex mt-1 items-center justify-start gap-2">
                    <svg
                      width="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 3.00056C0.106057 2.66204 0.167891 2.30121 0.325606 1.9893C0.882501 0.888426 1.79631 0.299832 3.03103 0.233302C3.41377 0.21256 3.79886 0.227824 4.18278 0.227824C8.38826 0.227824 12.5936 0.227824 16.7988 0.227824C18.205 0.227824 19.372 1.03401 19.8185 2.33526C19.9251 2.64237 19.9825 2.96436 19.9887 3.28938C20.0056 4.98159 19.9993 6.67458 19.9966 8.36679C19.9966 8.7781 19.7363 9.0857 19.3512 9.15889C19.1799 9.19091 19.0028 9.16314 18.8495 9.08022C18.6962 8.9973 18.576 8.86426 18.509 8.70335C18.4592 8.56258 18.4355 8.41392 18.439 8.26464C18.4339 6.65031 18.439 5.03598 18.4347 3.42126C18.4347 2.64169 18.0042 2.0574 17.2778 1.85233C17.1144 1.80932 16.9459 1.78865 16.7769 1.79089C12.2591 1.78775 7.74135 1.7871 3.22357 1.78893C2.22445 1.78893 1.56306 2.45423 1.56267 3.45805C1.56267 6.38015 1.56267 9.30303 1.56267 12.2267C1.56267 13.2297 2.22445 13.8958 3.22357 13.8958C7.74135 13.8974 12.2591 13.8974 16.7769 13.8958C17.7298 13.8958 18.3658 13.2959 18.4355 12.3418C18.4703 11.8604 18.8104 11.534 19.2542 11.5555C19.698 11.5771 20.0067 11.9336 19.9977 12.4165C19.9609 14.0481 18.628 15.3974 16.9961 15.4558C16.9507 15.4558 16.9049 15.4593 16.8595 15.4593C12.2898 15.4593 7.71996 15.4593 3.15 15.4593C1.77674 15.4593 0.62186 14.63 0.172587 13.3432C0.0986211 13.1307 0.0579209 12.9069 0.00195748 12.6881L0 3.00056Z"
                        fill="#262C4C"
                      />
                      <path
                        d="M7.30444 7.83695C7.30444 7.21079 7.3017 6.58736 7.30444 5.96276C7.3107 4.69556 8.53915 3.97547 9.63768 4.60281C10.7362 5.23015 11.8242 5.85984 12.9094 6.50166C13.9829 7.13643 13.9852 8.54882 12.9133 9.1836C11.8225 9.82933 10.7267 10.4654 9.62594 11.0918C8.54346 11.7086 7.31657 10.9916 7.30522 9.75184C7.30013 9.11237 7.30444 8.47446 7.30444 7.83695ZM8.88198 5.96746V9.72053L12.1142 7.844L8.88198 5.96746Z"
                        fill="#262C4C"
                      />
                    </svg>
                    {data && data[0]?.account?.videoCreated}
                  </p>
                </div>
              </div>
              <div className="sm:w-full w-1/2 flex flex-col justify-between items-start h-full py-2 gap-2 ">
                <div className="text-dark-blue sm:text-base text-sm font-bold">
                  <span className="font-normal opacity-50 sm:text-sm test-xs">
                    Email Address
                  </span>
                  <p className="mt-1">{data && data[0]?.email}</p>
                </div>
                <div className="text-dark-blue sm:text-base text-sm font-bold">
                  <span className="font-normal opacity-50 sm:text-sm test-xs">
                    Phone Number
                  </span>
                  <p className="mt-1">{(data && data[0]?.contact) || 'N/A'}</p>
                </div>
              </div>
              <div className="sm:w-full w-1/2 flex flex-col justify-between items-start h-full py-2 gap-2 ">
                <div className="text-dark-blue sm:text-base text-sm font-bold">
                  <span className="font-normal opacity-50 sm:text-sm test-xs">
                    Custom Domain
                  </span>
                  <p className="text-light-purple mt-1">{data ? (data[0]?.customDomain?.domain ?? 'N/A') : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full rounded-md">
          <h2 className="text-dark-blue font-bold text-lg my-4">Transaction</h2>
          <TransactionsTable />
        </div>
      </div>
    </>
  );
}
