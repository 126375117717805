import axios from 'axios';
import toast from 'react-hot-toast';
import { subscriptionActions } from './SubscriptionSlice';

// GET
export const fetchSubscriptionAPI = (page: number) => async dispatch => {
  page ||= 1;
  document.body.classList.add('loading-indicator');
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };

    let url = `${process.env.REACT_APP_API_URL}/stripe/product?type=recurring`;

    const response = await axios.get(url, { headers });
    document.body.classList.remove('loading-indicator');
    dispatch(subscriptionActions.getSubscription(response.data));
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    document.body.classList.remove('loading-indicator');
    console.log(error);
  }
};

export const fetchCustomDomainAPI = (page: number) => async dispatch => {
  page ||= 1;
  document.body.classList.add('loading-indicator');
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };

    let url = `${process.env.REACT_APP_API_URL}/stripe/product?type=custom_domain`;

    const response = await axios.get(url, { headers });
    document.body.classList.remove('loading-indicator');
    dispatch(subscriptionActions.getCustomDomain(response.data));
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    document.body.classList.remove('loading-indicator');
    console.log(error);
  }
};

// GET
export const fetchSubscriptionProductAPI = (page: number) => async dispatch => {
  page ||= 1;
  document.body.classList.add('loading-indicator');
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };

    let url = `${process.env.REACT_APP_API_URL}/stripe/product?type=one_time`;

    const response = await axios.get(url, { headers });
    document.body.classList.remove('loading-indicator');
    dispatch(subscriptionActions.getSubscriptionProduct(response.data));
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    document.body.classList.remove('loading-indicator');
    console.log(error);
  }
};

// // GET
// export const fetchUpgradePlanAPI = (page: number) => async dispatch => {
//   page ||= 1;
//   try {
//     // const userStringify: any = localStorage.getItem('user');
//     // const auth = JSON.parse(userStringify);
//     const headers = {
//       'Cache-Control': 's-maxage=1, stale-while-revalidate',
//       'access-token':
//         auth.token,
//     };

//     let url = `${process.env.REACT_APP_API_URL}/stripe/product?type=recurring`;

//     const response = await axios.get(url, { headers });
//     console.log({ response: response.data },":::");
//     dispatch(subscriptionActions.getUpgradePlan(response.data));
//   } catch (error) {
//     // dispatch(fetchTodosFailure(error.message));
//     console.log(error);
//   }
// };

// POST

export const postSubscriptionApi = (data: any) => async dispatch => {
  const userStringify: any = localStorage.getItem('user');
  const auth = JSON.parse(userStringify);
  const headers = {
    'Cache-Control': 's-maxage=1, stale-while-revalidate',
    'access-token': auth.token,
  };
  document.body.classList.add('loading-indicator');
  // console.log({ headers, data });
  try {
    toast.loading('Creating Subscription');
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/stripe/product`,
      data: data,
      headers: headers,
    });
    toast.dismiss();
    dispatch(fetchSubscriptionAPI(1));
    document.body.classList.remove('loading-indicator');
    toast.success('Subscription Created');
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.error);
    document.body.classList.remove('loading-indicator');
  }
};
export const postSubscriptionProductApi = (data: any) => async dispatch => {
  const userStringify: any = localStorage.getItem('user');
  const auth = JSON.parse(userStringify);
  const headers = {
    'Cache-Control': 's-maxage=1, stale-while-revalidate',
    'access-token': auth.token,
  };
  document.body.classList.add('loading-indicator');
  // console.log({ headers, data });
  try {
    toast.loading('Creating Product');
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/stripe/product`,
      data: data,
      headers: headers,
    });
    toast.dismiss();
    dispatch(fetchSubscriptionProductAPI(1));
    document.body.classList.remove('loading-indicator');
    toast.success('Product Created');
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.error);
    document.body.classList.remove('loading-indicator');
  }
};

// POST

export const postUpgradePlanApi = async data => {
  const upgradePlanStringify: any = localStorage.getItem('user');
  const auth = await JSON.parse(upgradePlanStringify);
  const headers = {
    'access-token': auth.token,
  };
  // console.log({ headers, data });
  try {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/campaign`,
      data: data,
      headers: headers,
    });
  } catch (error: any) {
    toast.error(error?.response?.data?.error);
  }
};

//PUT

export const updateSubscriptionAPI = (id: any, data: any) => async dispatch => {
  toast.loading('Updating Subscription');
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };
    document.body.classList.add('loading-indicator');

    await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/stripe/product/${id}`,
      data: data,
      headers: headers,
    });
    toast.dismiss();
    toast.success('Subscription Updated');
    // window.location.reload();

    document.body.classList.remove('loading-indicator');
    dispatch(fetchSubscriptionProductAPI(1));
    dispatch(fetchSubscriptionAPI(1));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.error);
    document.body.classList.remove('loading-indicator');
    // dispatch(fetchTodosFailure(error.message));
    console.log(error);
  }
};

export const updateCustomDomainAPI = (id: any, data: any) => async dispatch => {
  toast.loading('Updating Custom Domain Price');
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };
    document.body.classList.add('loading-indicator');

    await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/stripe/product/${id}`,
      data: data,
      headers: headers,
    });
    toast.dismiss();
    toast.success('Subscription Updated');
    window.location.reload();
    document.body.classList.remove('loading-indicator');
    dispatch(fetchSubscriptionAPI(1));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.error);
    document.body.classList.remove('loading-indicator');
    // dispatch(fetchTodosFailure(error.message));
    console.log(error);
  }
};

// DELETE

export const deleteSubscriptionApi = (id, deleted?) => async dispatch => {
  const subscriptionStringify: any = localStorage.getItem('user');
  const auth = JSON.parse(subscriptionStringify);
  // const headers = {
  //   'Cache-Control': 's-maxage=1, stale-while-revalidate',
  //   'access-token': auth.token,
  // };
  try {
    toast.loading('Updating Status');
    document.body.classList.add('loading-indicator');

    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };
    await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/stripe/product/${id}`,
      data: { status: deleted ? 'active' : 'inactive' },
      headers: headers,
    });
    toast.dismiss();
    toast.success('Status Updated');
    document.body.classList.remove('loading-indicator');

    dispatch(fetchSubscriptionAPI(1));
  } catch (error: any) {
    toast.dismiss();
    document.body.classList.remove('loading-indicator');
    toast.error(error?.response?.data?.error);
    // dispatch(fetchTodosFailure(error.message));
    console.log(error);
  }
};

export const deleteSubscriptionApiProduct = id => async dispatch => {
  const subscriptionStringify: any = localStorage.getItem('user');
  const auth = JSON.parse(subscriptionStringify);
  // const headers = {
  //   'Cache-Control': 's-maxage=1, stale-while-revalidate',
  //   'access-token': auth.token,
  // };
  try {
    toast.loading('Deleting Product');
    document.body.classList.add('loading-indicator');

    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };
    await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/stripe/product/${id}`,
      headers: headers,
    });
    toast.dismiss();
    toast.success('Product Deleted');
    document.body.classList.remove('loading-indicator');

    dispatch(fetchSubscriptionProductAPI(1));
  } catch (error: any) {
    toast.dismiss();
    toast.error(error?.response?.data?.error);
    document.body.classList.remove('loading-indicator');

    // dispatch(fetchTodosFailure(error.message));
    console.log(error);
  }
};
