import React, { BaseSyntheticEvent, useState } from 'react';
import { Menu, MenuItem, Pagination, PaginationItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import user from 'assets/user2big.png';
import { Link, useNavigate } from 'react-router-dom';
import { Domain } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { userStatusAPI } from 'utils/@reduxjs/User/UserApi';

export function UserlistTable({ activeTab, userPlan, setPage, page }) {
  const navigate = useNavigate();
  // const [page, setPage] = useState(1);
  const [userId, setUserId] = useState('');
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [showListMenuEl, setShowListMenuEl] = useState(null);
  const [showFullData, setFullData] = useState<any>({});
  const showListMenu = Boolean(showListMenuEl);

  const handleOpenListMenu = (
    event: Event | BaseSyntheticEvent,
    id,
    fullData,
  ) => {
    event.stopPropagation();
    setUserId(id);
    setFullData(fullData);
    setShowListMenuEl(event.currentTarget);
  };
  const handleCloseListMenu = (event: Event | BaseSyntheticEvent) => {
    event.preventDefault();
    setShowListMenuEl(null);
  };
  const handleBackdropClick = (event: Event | BaseSyntheticEvent) => {
    event.stopPropagation();
  };

  const data = useSelector((state: any) => state.users.users.response);
  console.log({ data });

  const handleViewDetails = () => {
    navigate(`/users/${userId}`);
  };
  const dispatch = useDispatch();
  const handleStatus = () => {


    let status =
      showFullData && showFullData?.status === 'active' ? false : true;
    data && dispatch(userStatusAPI(userId, { status }, activeTab, userPlan));
  };
  console.log({ showFullData }, 'Asd');
  return (
    data?.users?.length > 0 ? <>
      <div className="h-[calc(100vh-390px)] overflow-auto ">
        <table className="sm:w-full w-[250%] text-sm text-dark-blue font-bold">
          <thead>
            <tr>
              <th className="text-left pl-8 bg-extra-light-blue py-3  rounded-tl-md rounded-bl-md">
                Name
              </th>
              <th className="bg-extra-light-blue py-3 text-blue text-left">
                Email
              </th>
              <th className="bg-extra-light-blue py-3 text-blue text-left">
                User plan
              </th>
              <th className="bg-extra-light-blue py-3 text-blue text-left">
                Start date
              </th>
              <th className="bg-extra-light-blue py-3 text-blue text-left">
                Video created
              </th>
              <th className="text-center bg-extra-light-blue py-3 rounded-tr-md rounded-br-md min-w-[80px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.users?.length > 0 &&
              data?.users?.map((ele: any) => (
                <tr className="border-b last:border-b-0 border-extra-light-blue">
                  <td className="py-4 pr-2 px-4 flex items-center gap-2">
                    {ele?.profilePic ? (
                      <img
                        src={ele?.profilePic}
                        alt="campaign user name"
                        className="w-11 h-11 rounded-full text-sm"
                      />
                    ) : (
                      <AccountCircleIcon style={{ fontSize: '2.5rem' }} />
                    )}
                    <Link to={ele?._id}><p>{ele?.name}</p></Link>
                  </td>


                  <td className="py-4">
                    <div className="flex justify-start items-center gap-2">
                      {/* {ele?.product.map(ele => ( */}
                      <div className=" py-1  rounded-3xl  w-fit">
                        {ele?.email}
                      </div>
                    </div>
                  </td>


                  <td className="py-4 ">
                    <div className="flex justify-start items-center gap-2">
                      {/* {ele?.product.map(ele => ( */}
                      <div className="bg-[#C131DE26] px-4 py-1  capitalize rounded-3xl text-[#C131DE] w-fit">
                        {ele?.product?.name}
                      </div>
                      {/* ))} */}
                      {ele.account?.feature?.length !== 0 && ele.account?.feature?.includes("custom_domain") && <Domain />}
                    </div>
                  </td>
                  <td className="py-4 ">
                    <div className="flex items-center gap-2">
                      <svg
                        width="16"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_870_3191)">
                          <path
                            d="M15.8333 1.66667H15V0.833333C15 0.61232 14.9122 0.400358 14.7559 0.244078C14.5996 0.0877974 14.3877 0 14.1667 0C13.9457 0 13.7337 0.0877974 13.5774 0.244078C13.4211 0.400358 13.3333 0.61232 13.3333 0.833333V1.66667H6.66667V0.833333C6.66667 0.61232 6.57887 0.400358 6.42259 0.244078C6.26631 0.0877974 6.05435 0 5.83333 0C5.61232 0 5.40036 0.0877974 5.24408 0.244078C5.0878 0.400358 5 0.61232 5 0.833333V1.66667H4.16667C3.062 1.66799 2.00296 2.1074 1.22185 2.88852C0.440735 3.66963 0.00132321 4.72867 0 5.83333L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H15.8333C16.938 19.9987 17.997 19.5593 18.7782 18.7782C19.5593 17.997 19.9987 16.938 20 15.8333V5.83333C19.9987 4.72867 19.5593 3.66963 18.7782 2.88852C17.997 2.1074 16.938 1.66799 15.8333 1.66667ZM1.66667 5.83333C1.66667 5.17029 1.93006 4.53441 2.3989 4.06557C2.86774 3.59673 3.50363 3.33333 4.16667 3.33333H15.8333C16.4964 3.33333 17.1323 3.59673 17.6011 4.06557C18.0699 4.53441 18.3333 5.17029 18.3333 5.83333V6.66667H1.66667V5.83333ZM15.8333 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V8.33333H18.3333V15.8333C18.3333 16.4964 18.0699 17.1323 17.6011 17.6011C17.1323 18.0699 16.4964 18.3333 15.8333 18.3333Z"
                            fill="#262C4C"
                          />
                          <path
                            d="M10 13.75C10.6904 13.75 11.25 13.1904 11.25 12.5C11.25 11.8096 10.6904 11.25 10 11.25C9.30964 11.25 8.75 11.8096 8.75 12.5C8.75 13.1904 9.30964 13.75 10 13.75Z"
                            fill="#262C4C"
                          />
                          <path
                            d="M5.83398 13.75C6.52434 13.75 7.08398 13.1904 7.08398 12.5C7.08398 11.8096 6.52434 11.25 5.83398 11.25C5.14363 11.25 4.58398 11.8096 4.58398 12.5C4.58398 13.1904 5.14363 13.75 5.83398 13.75Z"
                            fill="#262C4C"
                          />
                          <path
                            d="M14.166 13.75C14.8564 13.75 15.416 13.1904 15.416 12.5C15.416 11.8096 14.8564 11.25 14.166 11.25C13.4757 11.25 12.916 11.8096 12.916 12.5C12.916 13.1904 13.4757 13.75 14.166 13.75Z"
                            fill="#262C4C"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_870_3191">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* {ele.product.map(product => { */}
                      {ele?.product &&
                        moment(ele?.createdAt).format('D MMM YYYY')}
                      {/* })} */}
                    </div>
                  </td>
                  <td className="py-4 ">
                    <div className="flex items-center gap-2">
                      <svg
                        width="16"
                        viewBox="0 0 20 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 3.00056C0.106057 2.66204 0.167891 2.30121 0.325606 1.9893C0.882501 0.888426 1.79631 0.299832 3.03103 0.233302C3.41377 0.21256 3.79886 0.227824 4.18278 0.227824C8.38826 0.227824 12.5936 0.227824 16.7988 0.227824C18.205 0.227824 19.372 1.03401 19.8185 2.33526C19.9251 2.64237 19.9825 2.96436 19.9887 3.28938C20.0056 4.98159 19.9993 6.67458 19.9966 8.36679C19.9966 8.7781 19.7363 9.0857 19.3512 9.15889C19.1799 9.19091 19.0028 9.16314 18.8495 9.08022C18.6962 8.9973 18.576 8.86426 18.509 8.70335C18.4592 8.56258 18.4355 8.41392 18.439 8.26464C18.4339 6.65031 18.439 5.03598 18.4347 3.42126C18.4347 2.64169 18.0042 2.0574 17.2778 1.85233C17.1144 1.80932 16.9459 1.78865 16.7769 1.79089C12.2591 1.78775 7.74135 1.7871 3.22357 1.78893C2.22445 1.78893 1.56306 2.45423 1.56267 3.45805C1.56267 6.38015 1.56267 9.30303 1.56267 12.2267C1.56267 13.2297 2.22445 13.8958 3.22357 13.8958C7.74135 13.8974 12.2591 13.8974 16.7769 13.8958C17.7298 13.8958 18.3658 13.2959 18.4355 12.3418C18.4703 11.8604 18.8104 11.534 19.2542 11.5555C19.698 11.5771 20.0067 11.9336 19.9977 12.4165C19.9609 14.0481 18.628 15.3974 16.9961 15.4558C16.9507 15.4558 16.9049 15.4593 16.8595 15.4593C12.2898 15.4593 7.71996 15.4593 3.15 15.4593C1.77674 15.4593 0.62186 14.63 0.172587 13.3432C0.0986211 13.1307 0.0579209 12.9069 0.00195748 12.6881L0 3.00056Z"
                          fill="#262C4C"
                        />
                        <path
                          d="M7.30444 7.83695C7.30444 7.21079 7.3017 6.58736 7.30444 5.96276C7.3107 4.69556 8.53915 3.97547 9.63768 4.60281C10.7362 5.23015 11.8242 5.85984 12.9094 6.50166C13.9829 7.13643 13.9852 8.54882 12.9133 9.1836C11.8225 9.82933 10.7267 10.4654 9.62594 11.0918C8.54346 11.7086 7.31657 10.9916 7.30522 9.75184C7.30013 9.11237 7.30444 8.47446 7.30444 7.83695ZM8.88198 5.96746V9.72053L12.1142 7.844L8.88198 5.96746Z"
                          fill="#262C4C"
                        />
                      </svg>
                      {ele?.account?.videoCreated} Videos Created
                    </div>
                  </td>
                  <td className="py-4  min-w-[80px]">
                    <div
                      className=" flex items-center justify-center cursor-pointer"
                      id={`list-menu-button-1`}
                      aria-controls={showListMenu ? `list-menu-1` : undefined}
                      aria-haspopup="true"
                      aria-expanded={showListMenu ? 'true' : undefined}
                      onClick={e => handleOpenListMenu(e, ele._id, ele)}
                    >
                      <MoreVert />
                    </div>
                  </td>
                </tr>
              ))}
            <Menu
              id={`list-menu-1`}
              anchorEl={showListMenuEl}
              open={showListMenu}
              onClose={handleCloseListMenu}
              classes={{ paper: '!rounded-xl !shadow-action-modal-shadow' }}
              sx={{
                '.MuiMenuItem-root': {
                  fontSize: '14px !important',
                  color: '#262C4C',
                },
              }}
              MenuListProps={{
                'aria-labelledby': `list-menu-button-1`,
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              onBackdropClick={handleBackdropClick}
            >
              <div // className={styles.headerAccountMenu}
              >
                <MenuItem onClick={() => handleViewDetails()}>
                  View Details
                </MenuItem>
                <MenuItem onClick={handleStatus}>
                  {showFullData && showFullData?.status === 'active'
                    ? 'Suspend User'
                    : 'Active User'}
                </MenuItem>
              </div>
            </Menu>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center text-dark-blue text-xs gap-2 py-2">
        <PaginationItem
          // slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
          sx={{
            borderColor: '#DDEBFB',
            minWidth: '40px',
            height: '40px',
          }}
          page={page}
          variant="outlined"
          shape="rounded"
        // {...item}
        />
        <span className="opacity-50">of {data?.totalPages || 1}</span>
        <Pagination
          sx={{
            '.MuiPagination-ul': {
              '>li': {
                display: 'none',
                button: {
                  margin: 0,
                },
              },
              '>li:first-child': {
                display: 'block',
                button: {
                  borderTopRightRadius: '0',
                  borderBottomRightRadius: '0',
                  borderRight: 'none',
                },
              },
              '>li:last-child': {
                display: 'block',
                button: {
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                },
              },
            },
          }}
          count={data?.totalPages || 1}
          page={page}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          renderItem={item => {
            // console.log(item);
            return (
              <PaginationItem
                sx={{
                  borderColor: '#C131DE',
                  minWidth: '40px',
                  height: '40px',
                  '.MuiSvgIcon-root': {
                    fill: '#C131DE',
                  },
                }}
                {...item}
              />
            );
          }}
        />
      </div>
    </> : <><p className='text-center h-[calc(100vh-390px)] pt-14 font-bold'>No Result found</p></>
  );
}
