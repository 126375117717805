import { Dialog } from '@mui/material';
import { Close } from '@mui/icons-material';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Subscriptions } from 'assets/icons';
import { Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    postSubscriptionApi,
    updateCustomDomainAPI,
    updateSubscriptionAPI,
} from 'utils/@reduxjs/subscription/SubscriptionApi';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useState } from 'react';

const CustomDomainEditPopUp = ({ openModal,
    handleCloseModal,
    edit,
    subscription, }) => {

    const [subscriptionData, setSubscriptionData] = useState<any>({
        id: subscription?._id,
        title: subscription?.name,
        price: subscription?.price,
        detail: subscription?.detail,
        featuresList: subscription?.featuresList,
        mostPopular: subscription?.mostPopular,
        videoLimit: subscription?.noOfVideos,
    }

    );

    console.log('subscriptionData', subscriptionData);


    const handleChange = e => {
        setSubscriptionData({
            ...subscriptionData,
            [e.target.name]: e.target.value,
        });
    };


    const dispatch = useDispatch()
    const handleSaveSubscription = () => {
        if (edit) {
            // setSubscriptions(subscriptions =>
            //   subscriptions.map(sub =>
            //     sub._id === subscription._id ? subscriptionData : sub,
            //   ),
            // );
            const data = {
                amount: subscriptionData.price,
            };
            if (
                subscriptionData.price
            ) {
                dispatch(updateCustomDomainAPI(subscriptionData.id, data));
                handleCloseModal();
                console.log({ subscriptionData });
            } else {
                toast.error('Fields cannot be empty!');
            }
        }
        // setSubscriptionData({
        //   id: Math.floor(Math.random() * 90 + 10),
        //   title: '',
        //   price: '',
        //   detail: '',
        //   featuresList: [''],
        //   mostPopular: false,
        //   videoLimit: 0,
        // });
    };
    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            classes={{
                paper:
                    'w-full lg:!max-w-[40%] !max-w-[95%] !text-dark-blue !rounded-md px-6',
                container: '!h-screen !w-screen',
            }}
        >
            <DialogTitle className="!font-bold !px-0  !text-base flex justify-between items-center border-b border-extra-light-blue">
                <div className="flex justify-start items-center gap-4">
                    <div className="w-10 h-10 rounded-full flex justify-center items-center bg-light-purple">
                        <Subscriptions />
                    </div>
                    {'Edit Custom Domain Price'}
                </div>
                <span className="cursor-pointer" onClick={handleCloseModal}>
                    <Close className="!w-5 !h-5" />
                </span>
            </DialogTitle>
            <DialogContent className="!px-0 sm:px-5">
                <div className="bg-white rounded-xl w-full text-dark-blue font-bold text-sm !px-0 !py-4">
                    <div className="flex justify-between items-center gap-4">

                        <div className="w-1/4">
                            <label htmlFor="price">Price/mo</label>
                            <div className="relative">
                                <span className="absolute top-[21px] left-2 sm:left-4">$</span>
                                <input
                                    type="number"
                                    min={0}
                                    name="price"
                                    value={subscriptionData?.price}
                                    onChange={handleChange}
                                    id="price"
                                    placeholder="250"
                                    className="bg-light-grey w-full mt-2 py-3 pr-4 pl-4 sm:pl-9 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
                                />
                            </div>
                        </div>
                    </div>


                    <div className="flex justify-center items-center gap-4 mt-2">
                        <button
                            className="border border-light-purple text-light-purple w-32 mt-1 rounded-md px-3 py-3 text-xs "
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>
                        <button
                            className="bg-light-purple w-32 mt-1 text-white rounded-md px-3 py-3 text-xs "
                            onClick={handleSaveSubscription}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default CustomDomainEditPopUp