import React, { useState } from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import user from '../../../../assets/user2big.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export function UserTable({ data }) {
  const [page, setPage] = useState(1);
  console.log('🚀OUTPUT --> data:', data);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      {data?.length === 0 ? (
        <div className="flex items-center justify-center flex-col w-full h-full">
          <div className="text-dark-blue font-bold text-lg mt-4 text-center w-[40%]">
            No Top User Found
            {/* <p className="text-xs opacity-50 mt-4">
                  It looks like you haven’t created a FAQ yet. Why not Create
                  one? <br />
                  Create a FAQ in a few simple steps.
                </p> */}
            {/* <button
                      className="bg-[#6585E8] text-white rounded-lg px-4 py-3 mt-4 text-sm inline-flex justify-center items-center gap-2"
                      onClick={() => navigate('/create-video')}
                    >
                      Create First Video
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </button> */}
          </div>
        </div>
      ) : (
        <div>
          <div className="overflow-x-auto">
            <table className="sm:w-full w-[200%] text-xs text-dark-blue font-bold">
              <thead>
                <tr>
                  <th className="bg-extra-light-blue py-3 pl-16 text-dark-blue font-bold text-left  rounded-tl-md rounded-bl-md">
                    Name
                  </th>
                  <th className="bg-extra-light-blue py-3 text-dark-blue text-left font-bold">
                    User plan
                  </th>
                  <th className="bg-extra-light-blue py-3 text-dark-blue text-left font-bold rounded-tr-md rounded-br-md">
                    No of videos created
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((ele: any) => (
                  <tr className="border-b border-extra-light-blue last:border-b-0">
                    <td className="py-4 pr-2 px-4 flex items-center gap-2 font-bold">
                      {ele?.user.length > 0 && ele?.user[0]?.profilePic ? (
                        <img
                          src={ele?.user[0]?.profilePic}
                          alt="campaign user name"
                          className="w-11 h-11 object-cover rounded-full text-sm"
                        />
                      ) : (
                        <AccountCircleIcon style={{ fontSize: '2.5rem' }} />
                      )}
                      {ele?.user.length > 0 && ele?.user[0]?.name}
                    </td>
                    <td className="py-3 px-2">
                      <div className="bg-[#C131DE26] px-4 py-1 rounded-3xl text-[#C131DE] w-fit">
                        {ele?.user[0]?.product[0]?.name}
                      </div>
                    </td>
                    <td className="py-3 px-2">
                      <div className="flex items-center gap-2">
                        <svg
                          width="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 3.00056C0.106057 2.66204 0.167891 2.30121 0.325606 1.9893C0.882501 0.888426 1.79631 0.299832 3.03103 0.233302C3.41377 0.21256 3.79886 0.227824 4.18278 0.227824C8.38826 0.227824 12.5936 0.227824 16.7988 0.227824C18.205 0.227824 19.372 1.03401 19.8185 2.33526C19.9251 2.64237 19.9825 2.96436 19.9887 3.28938C20.0056 4.98159 19.9993 6.67458 19.9966 8.36679C19.9966 8.7781 19.7363 9.0857 19.3512 9.15889C19.1799 9.19091 19.0028 9.16314 18.8495 9.08022C18.6962 8.9973 18.576 8.86426 18.509 8.70335C18.4592 8.56258 18.4355 8.41392 18.439 8.26464C18.4339 6.65031 18.439 5.03598 18.4347 3.42126C18.4347 2.64169 18.0042 2.0574 17.2778 1.85233C17.1144 1.80932 16.9459 1.78865 16.7769 1.79089C12.2591 1.78775 7.74135 1.7871 3.22357 1.78893C2.22445 1.78893 1.56306 2.45423 1.56267 3.45805C1.56267 6.38015 1.56267 9.30303 1.56267 12.2267C1.56267 13.2297 2.22445 13.8958 3.22357 13.8958C7.74135 13.8974 12.2591 13.8974 16.7769 13.8958C17.7298 13.8958 18.3658 13.2959 18.4355 12.3418C18.4703 11.8604 18.8104 11.534 19.2542 11.5555C19.698 11.5771 20.0067 11.9336 19.9977 12.4165C19.9609 14.0481 18.628 15.3974 16.9961 15.4558C16.9507 15.4558 16.9049 15.4593 16.8595 15.4593C12.2898 15.4593 7.71996 15.4593 3.15 15.4593C1.77674 15.4593 0.62186 14.63 0.172587 13.3432C0.0986211 13.1307 0.0579209 12.9069 0.00195748 12.6881L0 3.00056Z"
                            fill="#262C4C"
                          />
                          <path
                            d="M7.30444 7.83695C7.30444 7.21079 7.3017 6.58736 7.30444 5.96276C7.3107 4.69556 8.53915 3.97547 9.63768 4.60281C10.7362 5.23015 11.8242 5.85984 12.9094 6.50166C13.9829 7.13643 13.9852 8.54882 12.9133 9.1836C11.8225 9.82933 10.7267 10.4654 9.62594 11.0918C8.54346 11.7086 7.31657 10.9916 7.30522 9.75184C7.30013 9.11237 7.30444 8.47446 7.30444 7.83695ZM8.88198 5.96746V9.72053L12.1142 7.844L8.88198 5.96746Z"
                            fill="#262C4C"
                          />
                        </svg>
                        {ele?.count} Videos created
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="hidden justify-center items-center text-dark-blue text-xs gap-2 py-2">
            <PaginationItem
              // slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              sx={{
                borderColor: '#DDEBFB',
              }}
              page={page}
              variant="outlined"
              shape="rounded"
            // {...item}
            />
            <span className="opacity-50">of 10</span>
            <Pagination
              sx={{
                '.MuiPagination-ul': {
                  '>li': {
                    display: 'none',
                    button: {
                      margin: 0,
                    },
                  },
                  '>li:first-child': {
                    display: 'block',
                    button: {
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                      borderRight: 'none',
                    },
                  },
                  '>li:last-child': {
                    display: 'block',
                    button: {
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '0',
                    },
                  },
                },
              }}
              count={10}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              renderItem={item => {
                // console.log(item);
                return (
                  <PaginationItem
                    sx={{
                      borderColor: '#C131DE',
                    }}
                    {...item}
                  />
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
