import { createSlice } from '@reduxjs/toolkit';

type subscriptionState = {
  subscription: any;
  singleSubscription: any;
  subProducts: any;
  upgradePlan: any;
  customDomain: any;
};
const initialState: subscriptionState = {
  subscription: {},
  subProducts: {},
  customDomain: {},
  singleSubscription: {},
  upgradePlan: {},
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    getSubscription: (state, action) => {
      state.subscription = { ...state.subscription, ...action.payload };
    },
    getSubscriptionProduct: (state, action) => {
      state.subProducts = { ...state.subProducts, ...action.payload };
    },
    getCustomDomain: (state, action) => {
      state.customDomain = { ...state.customDomain, ...action.payload };
    },
  },
});

export default subscriptionSlice.reducer;

export const { reducer: subscriptionReducers, actions: subscriptionActions } =
  subscriptionSlice;
