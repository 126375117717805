import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FAQ } from 'assets/icons';
import { useDispatch } from 'react-redux';
import { createFaqsAPI, editFaqsAPI } from 'utils/@reduxjs/FAQ/FaqApi';
import { toast } from 'react-hot-toast';
import { Editor } from 'react-rte';
import RichTextEditor from 'react-rte';

export function FAQsCreateEditDialog({
  openModal,
  handleCloseModal,
  edit,
  faq,
}) {
  const [faqData, setFaqData] = useState(
    edit
      ? {
        title: faq?.title,
        description: faq?.description,
      }
      : {
        title: '',
        description: '',
      },
  );
  useEffect(() => {
    setFaqData(
      edit
        ? {
          title: faq?.title,
          description: faq?.description,
        }
        : {
          title: '',
          description: '',
        },
    );
  }, [faq]);

  const handleChange = e => {
    setFaqData({
      ...faqData,
      [e.target.name]: e.target.value,
    });
  };

  const dispatch = useDispatch();

  const handleSaveFaq = () => {
    if (edit) {
      // setFaqs(faqs => faqs.map(sub => (sub.id === faq.id ? faqData : sub)));
      if (faqData.title && faqData.description) {
        dispatch(editFaqsAPI(faqData, faq._id));
        return handleCloseModal();
      } else {
        toast.error('Fields cannot be empty!');
      }
    } else {
      console.log({ faqData });
      // setFaqs(faqs => [...faqs, faqData]);
      // if (faqData?.title && faq?.description) {
      if (faqData.title && faqData.description) {
        dispatch(createFaqsAPI(faqData));
        setFaqData({
          title: '',
          description: '',
        });
        return handleCloseModal();
      } else {
        toast.error('Fields cannot be empty!');
      }
    }
  };

  const [value, setValue] = useState(
    faq?.description
      ? RichTextEditor.createValueFromString(faq?.description, 'html')
      : RichTextEditor.createEmptyValue(),
  );
  const [htmlFAQ, sethtmlFAQ] = useState('');
  const onChange = html => {
    console.log(html);
    setFaqData({
      ...faqData,
      description: html,
    });
    // Perform any additional logic with the HTML content
  };

  const handleOnChange = newValue => {
    // console.log({ newValue: newValue.toString('html') });
    setValue(newValue);
    // setValue(newValue.toString('html'));
    if (onChange) {
      onChange(newValue.toString('html'));
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      classes={{
        paper:
          'w-full lg:!max-w-[40%] !max-w-[95%] !text-dark-blue !rounded-md px-6',
        container: '!h-screen !w-screen',
      }}
    >
      <DialogTitle className="!font-bold !px-0  !text-base flex justify-between items-center border-b border-extra-light-blue">
        <div className="flex justify-start items-center gap-4">
          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-light-purple">
            <FAQ />
          </div>
          {edit ? 'Edit FAQ' : 'Create New FAQ'}
        </div>
        <span className="cursor-pointer" onClick={handleCloseModal}>
          <Close className="!w-5 !h-5" />
        </span>
      </DialogTitle>
      <DialogContent className="!px-0 sm:px-5">
        <div className="bg-white rounded-xl w-full text-dark-blue font-bold text-sm !px-0 !py-4">
          <div className="">
            <label htmlFor="title">FAQ Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={faqData?.title}
              onChange={handleChange}
              placeholder="Add FAQ Title"
              className="bg-light-grey w-full mt-2 py-3 px-4 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="description">Description</label>
            {/* <textarea
              rows={5}
              name="description"
              value={faqData?.description}
              onChange={handleChange}
              id="description"
              placeholder="Add Detail"
              className="bg-light-grey resize-none w-full mt-2 py-3 px-4 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
            /> */}
            <RichTextEditor value={value} onChange={handleOnChange} />
            {/* < editorState={editorState} onChange={handleChange} /> */}
          </div>
          <div className="flex justify-center items-center gap-4 mt-2">
            <button
              className="border border-light-purple text-light-purple w-32 mt-1 rounded-md px-3 py-3 text-xs "
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              className="bg-light-purple w-32 mt-1 text-white rounded-md px-3 py-3 text-xs "
              onClick={handleSaveFaq}
            >
              Save
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
