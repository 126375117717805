import React, { useEffect, useState } from 'react';
import { Close, KeyboardArrowDown } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Subscriptions } from 'assets/icons';
import { FormControl, InputLabel, MenuItem, Select, selectClasses } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubscriptionAPI,
  postSubscriptionProductApi,
  updateSubscriptionAPI,
} from 'utils/@reduxjs/subscription/SubscriptionApi';
import { toast } from 'react-hot-toast';

export function ProductCreateEditDialog({
  openModal,
  handleCloseModal,
  edit,
  setProducts,
  product,
}) {
  const [selected, setSelected] = useState(product?.productId._id ?? '6464e627d019f7f81435f03e');
  const [selectedValue, setSelectedValue] = useState<any>({ name: 'Free' });


  console.log('selectedValue', selectedValue);

  const [productData, setProductData] = useState<any>(
    edit
      ? {
        id: product._id,
        noOfVideos: product?.noOfVideos,
        price: product?.price,
        name: product?.name
      }
      : {
        noOfVideos: 0,
        price: 0,
      },
  );

  console.log('productData', productData);

  console.log('selected', selected);
  console.log('product', product);


  const handleChange = e => {
    setProductData({
      ...productData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSaveSubscription = () => {
  //   // if (edit) {
  //   //   setProducts(products =>
  //   //     products.map(prod => (prod.id === product.id ? productData : prod)),
  //   //   );
  //   // } else {
  //   //   setProducts(products => [...products, productData]);
  //   // }
  //   // setProductData({
  //   //   id: Math.floor(Math.random() * 90 + 10),
  //   //   noOfVideos: 0,
  //   //   price: 0,
  //   // });
  //   handleCloseModal();
  // };

  const handleSaveSubscription = () => {
    if (edit) {
      // setSubscriptions(subscriptions =>
      //   subscriptions.map(sub =>
      //     sub._id === subscription._id ? productData : sub,
      //   ),
      // );
      const data = {
        amount: productData.price,
        name: selected,
        noOfVideos: productData.noOfVideos,
      };
      console.log('dataaaa', data);

      // if (productData.price && productData.title && productData.videoLimit) {
      dispatch(updateSubscriptionAPI(productData.id, data));
      dispatch(fetchSubscriptionAPI(1));

      handleCloseModal();
      console.log({ productData });
      // } else {
      //   toast.error('Fields cannot be empty!');
      // }
    } else {
      console.log({ productData });
      const data = {
        amount: productData.price,
        currency: 'usd',
        name: selectedValue.name,
        productId: selected,
        noOfVideos: productData.noOfVideos,
      };
      if (productData.price && selected) {
        dispatch(postSubscriptionProductApi(data));
        setProductData({
          ...productData,
          name: '',
          amount: '',
          currency: 'usd',
          noOfVideos: 0,
        });
        handleCloseModal();
        console.log({ productData });
      } else {
        toast.error('Fields cannot be empty!');
      }
    }
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchSubscriptionAPI(1));
  }, []);

  const data = useSelector(
    (state: any) => state.subscription.subscription.response,
  );

  // useEffect(() => {
  //   let find = data?.filter(d => d.name === product?.name)[0]
  //   setSelected(find?.name)
  // }, [data])

  // console.log({ selected, productData });
  console.log('setSelected', data);

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      classes={{
        paper:
          'w-full lg:!max-w-[40%] !max-w-[95%] !text-dark-blue !rounded-md px-6',
        container: '!h-screen !w-screen',
      }}
    >
      <DialogTitle className="!font-bold !px-0  !text-base flex justify-between items-center border-b border-extra-light-blue">
        <div className="flex justify-start items-center gap-4">
          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-light-purple">
            <Subscriptions />
          </div>
          {edit ? 'Edit Product' : 'Create New Product'}
        </div>
        <span className="cursor-pointer" onClick={handleCloseModal}>
          <Close className="!w-5 !h-5" />
        </span>
      </DialogTitle>
      <DialogContent className="!px-0 sm:px-5">
        <div className="bg-white rounded-xl w-full text-dark-blue font-bold text-sm !px-0 !py-4">
          <div className=" w-1/2 flex flex-col gap-2 mb-[0.5rem]">
            <label htmlFor="noOfVideos">Select Plan</label>
            <FormControl
              sx={{ m: 1 }}
              variant="standard"
              className="w-[48%] sm:w-auto !m-0"
            >
              {/* <InputLabel className='ml-2'>Select Plan</InputLabel> */}
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                disableUnderline
                value={selected}
                onChange={e => {
                  setSelected(e.target.value);
                }}
                IconComponent={KeyboardArrowDown}
                className="sm:!w-40 !w-full"
                // label="Select Place"
                sx={{
                  background: 'transparent',
                  border: '1px solid #262C4C',
                  borderRadius: '6px',
                  opacity: '0.5',
                  fontSize: '14px',
                  padding: '5px 10px 5px 16px',
                  '.MuiSvgIcon-root': {
                    right: '5px',
                  },
                  '.MuiSelect-select': {
                    background: 'transparent !important',
                    color: '#262C4C99',
                    fontWeight: 700,
                  },
                }}
              >
                <MenuItem value="select">Select Plan</MenuItem>
                {data &&
                  data.map(ele => (
                    <MenuItem
                      onClick={() => setSelectedValue(ele)}
                      value={ele._id}
                    >
                      {ele.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex justify-between items-center gap-4">
            <div className="w-1/2">
              <label htmlFor="price">Price</label>
              <div className="relative">
                <span className="absolute top-[21px] left-2 sm:left-4">$</span>
                <input
                  type="number"
                  min={0}
                  name="price"
                  value={productData?.price}
                  onChange={handleChange}
                  id="price"
                  placeholder="250"
                  className="bg-light-grey w-full mt-2 py-3 pr-4 pl-4 sm:pl-9 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
                />
              </div>
            </div>
            <div className="w-1/2">
              <label htmlFor="noOfVideos">No. of Videos</label>
              <div className="relative">
                <input
                  type="number"
                  min={0}
                  name="noOfVideos"
                  value={productData?.noOfVideos}
                  onChange={handleChange}
                  id="noOfVideos"
                  placeholder="Video Limit"
                  className="bg-light-grey w-full mt-2 py-3 px-4 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-4 mt-2">
            <button
              className="border border-light-purple text-light-purple w-32 mt-1 rounded-md px-3 py-3 text-xs "
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              className="bg-light-purple w-32 mt-1 text-white rounded-md px-3 py-3 text-xs "
              onClick={handleSaveSubscription}
            >
              Save
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
