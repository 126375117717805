import * as React from 'react';
import AnalyticsCard from 'app/components/Dashboard/AnalyticsCard';
import { Helmet } from 'react-helmet-async';
import TotalUsersCard from 'app/components/Dashboard/TotalUsersCard';
import { UserTable } from 'app/components/Dashboard/UserTable';
import { UsersGroup } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomdomainCountAPI,
  fetchUserCountAPI,
  fetchUserLocationAPI,
  fetchUserPlanAPI,
  fetchVideoCountAPI,
  fetchVideoTopUsersAPI,
} from 'utils/@reduxjs/dashboard/dashboardApi';
import { Skeleton } from '@mui/material';

export function Dashboard() {
  const [userCount, setUserCount] = React.useState<any>({});

  const [filterType, setFilterType] = React.useState<any>([
    {
      filter: 'all',
      type: 'userCount',
    },
    {
      filter: 'all',
      type: 'userCountPlans',
    },
    {
      filter: 'all',
      type: 'videoCount',
    },
    {
      filter: 'all',
      type: 'userDomainCount',
    },
  ]);

  console.log({ filterType });

  const [analyticsCards, setAnalyticsCards] = React.useState<any>([
    {
      id: 1,
      icon: 'account',
      title: 'No of users',
      filter: 'all',
      color: '#6585E8',
      subCards: [
        {
          title: 'Total users',
          value: '25,000',
        },
        {
          title: 'New users',
          value: '3,000',
        },
      ],
    },
    {
      id: 2,
      icon: 'plans',
      title: 'No of users - Plans',
      filter: 'all',
      color: '#C131DE',
      subCards: [
        {
          title: 'Free',
          value: '25,000',
        },
        {
          title: 'Premium',
          value: '3,000',
        },
        {
          title: 'Enterprise',
          value: '3,000',
        },
      ],
    },
    {
      id: 3,
      icon: 'domain',
      title: 'Custom domain users',
      filter: 'all',
      color: '#6585E8',
      subCards: [
        {
          title: 'Free',
          value: '250',
        },
        {
          title: 'Premium',
          value: '105',
        },
      ],
    },
    {
      id: 4,
      icon: 'videos',
      title: 'No of videos',
      filter: 'all',
      color: '#C131DE',
      subCards: [
        {
          title: 'No of videos',
          value: '25,000',
        },
        {
          title: 'New videos created',
          value: '3,000',
        },
      ],
    },
  ]);

  const dispatch = useDispatch();

  const state = useSelector((state: any) => state.dashboard);

  React.useEffect(() => {
    dispatch(fetchUserCountAPI('all'));
    dispatch(fetchUserPlanAPI('all'));
    dispatch(fetchUserLocationAPI('all'));
    dispatch(fetchVideoCountAPI('all'));
    dispatch(fetchVideoTopUsersAPI());
    dispatch(fetchCustomdomainCountAPI('all'));
  }, []);

  return (
    <>
      <Helmet>
        <title>One Clip - Admin</title>
        <meta name="description" content="One Clip" />
      </Helmet>
      <div className="mt-4">
        <h1 className="text-white font-bold text-xl">Dashboard</h1>
        <div className="flex justify-start items-center flex-col sm:flex-row gap-4 my-4 ">
          {/* {[analyticsCards[0], analyticsCards[1], analyticsCards[2]].map(
            card => (
            
            ),
          )} */}
          {/* {!state.userCount.isLoading ? ( */}
          <AnalyticsCard
            data={state.userCount.response}
            analyticsCards={analyticsCards[0]}
            type="userCount"
            setFilterType={setFilterType}
            filterType={filterType}
          />
          {/* ) : (
            <div
              key={analyticsCards.id}
              className={`bg-white rounded-lg p-4 w-full shadow-dashboard-card-shadow`}
            >
              <div className="flex justify-between items-center mb-4">
                <Skeleton
                  sx={{ bgcolor: 'grey.900' }}
                  variant="rectangular"
                  width={210}
                  height={118}
                />
              </div>
            </div>
          )} */}
          {state.userCountPlans && (
            <AnalyticsCard
              data={state.userCountPlans.response}
              analyticsCards={analyticsCards[1]}
              type="userCountPlans"
              setFilterType={setFilterType}
              filterType={filterType}
            />
          )}
          {state.customdomainCount && (
            <AnalyticsCard
              data={state.customdomainCount.response}
              analyticsCards={analyticsCards[2]}
              setFilterType={setFilterType}
              filterType={filterType}
              type="userDomainCount"
            />
          )}
        </div>
        <div className="flex justify-between items-start flex-col-reverse sm:flex-row gap-4">
          <div className="w-full sm:w-2/3 bg-white rounded-md p-4">
            <p className="text-dark-blue font-bold mb-4 flex items-center justify-start gap-4">
              <UsersGroup />
              Top Users
            </p>
            <UserTable data={state?.videoTopUsers?.response} />
          </div>
          <div className="w-full sm:w-[32.9%]">
            {/* {[analyticsCards[3]].map(card => ( */}
            <AnalyticsCard
              data={[
                {
                  count: state.videoCount.response?.totalCount,
                  name: 'No of videos',
                },
                {
                  count: state.videoCount.response?.count,
                  name: 'New videos created',
                },
              ]}
              analyticsCards={analyticsCards[3]}
              type="videoCount"
              setFilterType={setFilterType}
              filterType={filterType}
            />
            {/* ))} */}
            <TotalUsersCard users={state.userCountLocation.response} />
          </div>
        </div>
      </div>
    </>
  );
}
