import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    DialogActions,
    IconButton,
    Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = ({ text, confirmOnClick, cancelOnclick }) => {
    return (
        <Dialog open={true} maxWidth="sm" fullWidth>
            <DialogTitle>Are you sure?</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>{text}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelOnclick} color="primary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={confirmOnClick} color="error" variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
