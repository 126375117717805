import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import visa from 'assets/visa.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleUserAPI } from 'utils/@reduxjs/User/UserApi';
import moment from 'moment';

export function TransactionsTable() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  console.log('🚀OUTPUT --> page:', page);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const data = useSelector((state: any) => state.users.transactions.response);
  console.log(data, 'LLLAAAALLAL');
  return (
    <>
      {data === 'undefined' || !data || data?.length === 0 ? (
        <div className="flex items-center justify-center flex-col w-full h-full">
          <div className="text-dark-blue font-bold text-lg mt-4 text-center w-[40%]">
            No Transactions Data
            {/* <p className="text-xs opacity-50 mt-4">
              It looks like you haven’t created a FAQ yet. Why not Create one?{' '}
              <br />
              Create a FAQ in a few simple steps.
            </p> */}
            {/* <button
                      className="bg-[#6585E8] text-white rounded-lg px-4 py-3 mt-4 text-sm inline-flex justify-center items-center gap-2"
                      onClick={() => navigate('/create-video')}
                    >
                      Create First Video
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </button> */}
          </div>
        </div>
      ) : (
        <div>
          <div className="overflow-auto">
            <table className="sm:w-full w-[250%] text-sm text-dark-blue font-bold">
              <thead>
                <tr>
                  <th className="bg-extra-light-blue py-4 px-4 text-left rounded-tl-md">
                    Date
                  </th>
                  <th className="bg-extra-light-blue py-4 text-left">
                    Plan Details
                  </th>
                  <th className="bg-extra-light-blue py-4 text-left">
                    Date
                  </th>
                  <th className="bg-extra-light-blue py-4 text-left">Method</th>
                  <th className="bg-extra-light-blue py-4 text-left ">
                    Payment Status
                  </th>
                  <th className="bg-extra-light-blue py-4 text-left ">
                    Amount
                  </th>
                  <th className="bg-extra-light-blue py-4 rounded-tr-md min-w-[80px]"></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((ele, i) => (
                  <tr className="border-b last:border-b-0 border-extra-light-blue group">
                    <td className="bg-white py-4 pr-2 px-4 group-last:rounded-bl-md">
                      {moment(ele?.createdAt).format('D MMM YYYY')}
                    </td>
                    <td className="bg-white py-4">
                      <div className="flex items-center capitalize gap-2">
                        {ele?.stripeProductId?.type?.replaceAll('_', ' ')}
                        <div
                          className={`${ele?.stripeProductId?.status === 'active' ? 'bg-[#0AC011]' : 'bg-[#E21C51]'
                            } px-4 py-1 rounded-3xl text-white w-fit`}
                        >
                          {ele?.stripeProductId?.status}
                        </div>
                      </div>
                    </td>
                    <td className="bg-white py-4">
                      <div className="flex items-center gap-2">
                        {ele?.billingStartDate.split('T')[0]}
                      </div>
                    </td>
                    <td className="bg-white py-4">
                      <img src={visa} alt="visa" className="max-w-full" />
                    </td>
                    <td className="bg-white py-4">
                      <div className="bg-[#0AC01126] capitalize px-4 py-1 rounded-3xl text-[#0AC011] w-fit">
                        {ele?.paymentStatus}
                      </div>
                    </td>
                    <td className="bg-white py-4">
                      <div className="flex items-center gap-2">
                        ${ele?.amount}
                      </div>
                    </td>
                    <td className="bg-white py-4 min-w-[80px] group-last:rounded-br-md">
                      <div className=" flex items-center justify-center cursor-pointer">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_870_7457)">
                            <path
                              d="M-0.000244141 12.8011C-0.000244141 12.27 -0.000244141 11.7389 -0.000244141 11.2078C0.0149765 11.14 0.0390349 11.0728 0.0439448 11.0041C0.139688 9.70181 0.430353 8.44126 0.980751 7.26023C2.98104 2.96612 6.33498 0.533869 11.0637 0.0552725C12.8872 -0.129294 14.6607 0.153446 16.3629 0.827408C21.5654 2.8866 24.6263 8.09127 23.8922 13.6405C23.6256 15.6575 22.8808 17.4958 21.6568 19.1235C19.681 21.7506 17.0645 23.3376 13.8127 23.8624C13.4754 23.9168 13.1357 23.9546 12.7974 24.0003H11.2036C10.9115 23.9635 10.6184 23.9321 10.3267 23.8894C8.3387 23.5997 6.51959 22.8826 4.90964 21.6805C2.27009 19.7101 0.674374 17.0943 0.140179 13.8398C0.0837149 13.4952 0.0459088 13.1477 -0.000244141 12.8011ZM22.5008 12.0226C22.5238 6.2515 17.8084 1.52395 12.0123 1.50873C6.24071 1.49253 1.53753 6.17541 1.49973 11.975C1.46192 17.7403 6.16952 22.4698 11.9764 22.5007C17.7465 22.5316 22.4777 17.8213 22.5008 12.0221V12.0226Z"
                              fill="#6585E8"
                            />
                            <path
                              d="M12.8241 14.6699C12.8746 14.6012 12.9178 14.5246 12.9773 14.4647C13.9091 13.5301 14.8425 12.5965 15.7774 11.6648C16.0818 11.3614 16.4338 11.3006 16.7515 11.4876C17.1482 11.7208 17.2459 12.2421 16.9577 12.6014C16.9042 12.6681 16.8423 12.7285 16.7814 12.7889C15.4504 14.1201 14.1193 15.4509 12.7877 16.7811C12.3095 17.2587 11.6938 17.2602 11.2171 16.7841C9.87421 15.4425 8.52939 14.1034 7.1939 12.7545C7.0633 12.6225 6.94595 12.4438 6.90176 12.2671C6.81731 11.9289 6.99063 11.6177 7.28571 11.465C7.57834 11.3133 7.92449 11.3698 8.18668 11.6299C9.1338 12.5714 10.0775 13.5168 11.0207 14.4623C11.0811 14.5226 11.1272 14.5968 11.1797 14.6645L11.2504 14.636C11.2504 14.5565 11.2504 14.477 11.2504 14.397C11.2504 11.867 11.2495 9.33661 11.2509 6.80668C11.2509 6.21322 11.7915 5.83427 12.3036 6.07185C12.6183 6.21813 12.7514 6.47829 12.7509 6.82681C12.747 9.31796 12.7489 11.8086 12.7489 14.2998C12.7489 14.4078 12.7489 14.5158 12.7489 14.6233C12.774 14.639 12.7985 14.6542 12.8236 14.6699H12.8241Z"
                              fill="#6585E8"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_870_7457">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="flex justify-center mt-4 items-center text-dark-blue text-xs gap-2 py-2">
            <PaginationItem
              // slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              sx={{
                borderColor: '#DDEBFB',
              }}
              page={page}
              variant="outlined"
              shape="rounded"
            // {...item}
            />
            <span className="opacity-50">of 10</span>
            <Pagination
              sx={{
                '.MuiPagination-ul': {
                  '>li': {
                    display: 'none',
                    button: {
                      margin: 0,
                    },
                  },
                  '>li:first-child': {
                    display: 'block',
                    button: {
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                      borderRight: 'none',
                    },
                  },
                  '>li:last-child': {
                    display: 'block',
                    button: {
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '0',
                    },
                  },
                },
              }}
              count={10}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              renderItem={item => {
                // console.log(item);
                return (
                  <PaginationItem
                    sx={{
                      borderColor: '#C131DE',
                    }}
                    {...item}
                  />
                );
              }}
            />
          </div> */}
        </div>
      )}
    </>
  );
}
