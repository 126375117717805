import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { AddRounded } from '@mui/icons-material';
import { FAQsCard } from 'app/components/FAQsCard';
import { FAQsCreateEditDialog } from 'app/components/FAQsCreateEditDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFaqsAPI } from 'utils/@reduxjs/FAQ/FaqApi';

export function FAQs() {
  const [faqs, setFaqs] = React.useState([
    {
      id: 1,
      title: 'Is there a free trial available?',
      description:
        'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
    },
    {
      id: 2,
      title: 'Can I change my plan later?',
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      id: 3,
      title: 'What is your cancellation policy?',
      description:
        'The 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker.',
    },
    {
      id: 4,
      title: 'Can other info be added to an invoice?',
      description:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.',
    },
    {
      id: 5,
      title: 'How does billing work?',
      description:
        'Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero.',
    },
    {
      id: 6,
      title: 'How do I change my account email?',
      description:
        'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.',
    },
  ]);
  const [openModal, setOpenModal] = React.useState<boolean | false>(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchFaqsAPI());
  }, []);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const data = useSelector((state: any) => state.faq.faq.response);
  console.log({ data });

  return (
    <>
      <Helmet>
        <title>One Clip - Admin</title>
        <meta name="description" content="One Clip" />
      </Helmet>
      <div className="mt-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-white font-bold text-xl">FAQs list</h1>
          <div
            className="bg-white border-2 flex justify-start items-center gap-2 border-light-purple text-light-purple font-bold py-3 px-5 rounded-md cursor-pointer text-sm"
            onClick={handleClickOpenModal}
          >
            <AddRounded width={10} />
            Create FAQ
          </div>
        </div>
        <div className="flex flex-col bg-white rounded-md min-h-[calc(100vh-225px)]">
          {data?.length === 0 ? (
            <div className="flex items-center justify-center flex-col w-full h-full">
              <div className="text-dark-blue font-bold text-lg mt-4 text-center w-[40%]">
                No FAQ Created
                <p className="text-xs opacity-50 mt-4">
                  It looks like you haven’t created a FAQ yet. Why not Create
                  one? <br />
                  Create a FAQ in a few simple steps.
                </p>
                {/* <button
                      className="bg-[#6585E8] text-white rounded-lg px-4 py-3 mt-4 text-sm inline-flex justify-center items-center gap-2"
                      onClick={() => navigate('/create-video')}
                    >
                      Create First Video
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </button> */}
              </div>
            </div>
          ) : (
            data?.map(faq => <FAQsCard faq={faq} />)
          )}
        </div>
      </div>

      <FAQsCreateEditDialog
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        // setFaqs={setFaqs}
        edit={false}
        faq={null}
      />
    </>
  );
}
