import React, { useEffect, useState } from 'react';
import { Check, Delete, Edit } from 'assets/icons';
import { SubscriptionCreateEditDialog } from '../SubscriptionCreateEditDialog';
import { deleteSubscriptionApi } from 'utils/@reduxjs/subscription/SubscriptionApi';
import { useDispatch } from 'react-redux';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import CustomDomainEditPopUp from '../CustomDomainEditPopUp';

export function CustomDomainCard({ subscription, setSubscriptions }) {
  const [openModal, setOpenModal] = useState<boolean | false>(false);
  console.log({ subscription });

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    setIsDeleted(subscription.status == 'active' ? true : false);
  }, [subscription.deleted]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const dispatch = useDispatch();

  const handleDelete = () => {
    // props.setProducts(products =>
    //   products.filter(prod => prod.id !== props.product.id),
    // );
    // dispatch(deleteSubscriptionApi(subscription._id));
  };
  console.log({ id: subscription._id });
  const [isDeleted, setIsDeleted] = React.useState(false);

  const handleCheckboxChange = event => {
    dispatch(deleteSubscriptionApi(subscription._id, event.target.checked));
    setIsDeleted(event.target.checked);
  };
  console.log({ isDeleted });
  return (
    <div
      key={subscription._id}
      className="flex justify-between items-start gap-8 w-full bg-white rounded-md p-6 shadow-dashboard-card-shadow"
    >
      <div className="w-full h-full ">
        <div className="flex justify-between items-center gap-4 w-full border-b pb-4 mb-4 border-extra-light-blue text-dark-blue">
          <div>
            <p className="font-bold capitalize text-base mb-1">
              {subscription.name}
            </p>
            <p className="text-sm opacity-50">
              {' '}
              Price/mo : ${subscription.price}
            </p>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row gap-4">
            <div
              className="bg-[#f0f3fd] text-light-purple rounded-md w-8 h-8 flex items-center justify-center cursor-pointer"
              onClick={handleClickOpenModal}
            >
              <Edit />
            </div>
            {/* <div
              className="bg-[#f9eafc] text-light-purple rounded-md w-8 h-8 flex items-center justify-center cursor-pointer"
              onClick={handleDelete}
            >
              <Delete />
            </div> */}
            {/* <FormControlLabel
              control={<Switch defaultChecked />}
              
            /> */}
            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDeleted}
                    onChange={handleCheckboxChange}
                    // defaultChecked={subscription.deleted}
                    name={'gilad'}
                  />
                }
                label={subscription.status == 'active' ? 'Active' : 'Inactive'}
              />
            </FormGroup> */}
          </div>
        </div>
        <div className="flex items-center justify-start flex-wrap text-dark-blue text-sm gap-4">
          {subscription.featuresList?.map(feature => (
            <div className="flex justify-start items-center gap-4 w-full sm:w-[24.5%]">
              <Check />
              {feature}
            </div>
          ))}
        </div>
      </div>
      <CustomDomainEditPopUp
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        edit={true}
        subscription={subscription}
      />
    </div>
  );
}
