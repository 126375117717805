import axios from 'axios';
import { usersActions } from './UserSlice';

interface UserAPItype {
  page: number;
  activeTab: string;
  planId?: any;
  checked?: string;
  userSearch?: string;
  selectedStartDateSend?: any;
  selectedEndDateSend?: any;
  noOfItems?: any;
}
export const fetchUsersAPI =
  ({
    page,
    activeTab,
    planId,
    checked,
    userSearch,
    selectedStartDateSend,
    selectedEndDateSend,
    noOfItems,
  }: UserAPItype) =>
  async dispatch => {
    page ||= 1;

    console.log({ page, noOfItems });
    try {
      const userStringify: any = localStorage.getItem('user');
      const auth = JSON.parse(userStringify);
      const headers = {
        'Cache-Control': 's-maxage=1, stale-while-revalidate',
        'access-token': auth.token,
      };

      let apiUrl = `${process.env.REACT_APP_API_URL}/user/all?pageSize=${
        noOfItems || 10
      }&page=${page}&status=${activeTab}`;
      document.body.classList.add('loading-indicator');
      if (planId && planId !== 'select') {
        apiUrl += `&planId=${planId}`;
      }
      if (checked !== 'false') {
        apiUrl += `&customDomain=${checked}`;
      }
      if (userSearch) {
        apiUrl += `&name=${userSearch}`;
      }
      if (selectedStartDateSend && selectedEndDateSend) {
        apiUrl += `&from=${selectedStartDateSend}&to=${selectedEndDateSend}`;
      }

      const response = await axios.get(apiUrl, { headers });
      response && document.body.classList.remove('loading-indicator');

      dispatch(usersActions.getUsers(response.data));
    } catch (error) {
      // dispatch(fetchTodosFailure(error.message));
      document.body.classList.remove('loading-indicator');

      console.log(error);
    }
  };

export const fetchSingleUserAPI = (id: any) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };
    document.body.classList.add('loading-indicator');

    let url = `${process.env.REACT_APP_API_URL}/user/${id}`;

    const response = await axios.get(url, { headers });
    document.body.classList.remove('loading-indicator');

    dispatch(usersActions.getSingleUser(response.data));
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    document.body.classList.remove('loading-indicator');

    console.log(error);
  }
};

export const unlockFeatureSingleUserAPI = (data, id) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };

    let url = `${process.env.REACT_APP_API_URL}/user/unlock-feature`;

    const response = await axios.put(url, data, { headers });

    dispatch(fetchSingleUserAPI(id));
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    document.body.classList.remove('loading-indicator');

    console.log(error);
  }
};

export const fetchTransactionsAPI = (id: any) => async dispatch => {
  try {
    const userStringify: any = localStorage.getItem('user');
    const auth = JSON.parse(userStringify);
    const headers = {
      'Cache-Control': 's-maxage=1, stale-while-revalidate',
      'access-token': auth.token,
    };
    document.body.classList.add('loading-indicator');

    let url = `${process.env.REACT_APP_API_URL}/user/transactions/${id}`;

    const response = await axios.get(url, { headers });
    document.body.classList.remove('loading-indicator');

    dispatch(usersActions.getTransactionsUser(response.data));
  } catch (error) {
    // dispatch(fetchTodosFailure(error.message));
    document.body.classList.remove('loading-indicator');

    console.log(error);
  }
};

export const userStatusAPI =
  (id: any, data: any, activeTab?, userPlan?) => async dispatch => {
    try {
      console.log('its being called');

      const userStringify: any = localStorage.getItem('user');
      const auth = JSON.parse(userStringify);
      const headers = {
        'Cache-Control': 's-maxage=1, stale-while-revalidate',
        'access-token': auth.token,
      };
      document.body.classList.add('loading-indicator');

      await axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/user/${id}/status`,
        data: data,
        headers: headers,
      });
      document.body.classList.remove('loading-indicator');
      dispatch(fetchUsersAPI({ page: 1, activeTab, planId: userPlan }));
      dispatch(fetchSingleUserAPI(id));
    } catch (error) {
      // dispatch(fetchTodosFailure(error.message));
      document.body.classList.remove('loading-indicator');

      console.log(error);
    }
  };
