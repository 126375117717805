import React from 'react';
import { Header } from 'app/components/Header';

export function Layout(props) {
  return (
    <div className="relative max-w-[1700px] mx-auto xl-px[12%] sm:px-[8%] px-[5%] min-h-screen pb-8">
      <div className="">
        {/* Header Background */}
        <div className="fixed top-0 w-full left-0 -z-10 h-1/4 min-h-[250px]">
          <img
            className="w-full h-full object-cover"
            src="/assets/images/headerBg.png"
            alt="header-bg"
          />
          <img
            className="w-full absolute object-cover bottom-0 min-h-[70px]"
            src="/assets/images/header-bg-cov.png"
            alt="header-bg"
          />
          <div className="h-20 bg-header-bg"></div>
        </div>
        <Header setSidebarOpen={{}} />
      </div>
      {props.children}
    </div>
  );
}
