import React, { useState } from 'react';
import { Account, Domain, Subscriptions, Videos } from 'assets/icons';
import {
  fetchCustomdomainCountAPI,
  fetchUserCountAPI,
  fetchUserLocationAPI,
  fetchUserPlanAPI,
  fetchVideoCountAPI,
  fetchVideoTopUsersAPI,
} from 'utils/@reduxjs/dashboard/dashboardApi';
import { useDispatch } from 'react-redux';

export default function AnalyticsCard({
  data,
  type,
  analyticsCards,
  setFilterType,
  filterType,
}: {
  data: any;
  type?: string;
  analyticsCards: any;
  setFilterType: any;
  filterType: any;
}) {
  console.log({ data, analyticsCards, type });

  return (
    <div
      key={analyticsCards.id}
      className={`bg-white rounded-lg p-4 w-full shadow-dashboard-card-shadow`}
    >
      <div className="flex justify-between items-center mb-4">
        <div className="font-bold flex items-start gap-2 text-sm text-dark-blue">
          {
            {
              account: <Account />,
              plans: <Subscriptions color="#262C4C" />,
              domain: <Domain />,
              videos: <Videos />,
            }[analyticsCards.icon]
          }
          {analyticsCards.title}
        </div>
        <FilterTab
          setFilterType={setFilterType}
          filterType={filterType}
          type={type}
        />
      </div>

      {type == 'userCount' ? (
        <div className="flex justify-between items-center gap-4">
          <div className="bg-[#F5F7FB] w-full rounded-md py-6 flex flex-col justify-center items-center text-center">
            <p
              className={`font-bold text-xl text-[${
                analyticsCards.color || '#6585E8'
              }]`}
            >
              {data?.totalCount || 0}
            </p>
            <p className="text-[#202020] opacity-50 text-sm">
              {analyticsCards?.subCards[0].title}
            </p>
          </div>
          <div className="bg-[#F5F7FB] w-full rounded-md py-6 flex flex-col justify-center items-center text-center">
            <p
              className={`font-bold text-xl text-[${
                analyticsCards.color || '#6585E8'
              }]`}
            >
              {data?.count || 0}
            </p>
            <p className="text-[#202020] opacity-50 text-sm">
              {analyticsCards?.subCards[1].title}
            </p>
          </div>
        </div>
      ) : type == 'userDomainCount' ? (
        <div className="flex justify-between items-center gap-4">
          <div className="bg-[#F5F7FB] w-full rounded-md py-6 flex flex-col justify-center items-center text-center">
            <p
              className={`font-bold text-xl text-[${
                analyticsCards.color || '#6585E8'
              }]`}
            >
              {data?.premium || 0}
            </p>
            <p className="text-[#202020] opacity-50 text-sm">
              {analyticsCards?.subCards[1].title}
            </p>
          </div>
          <div className="bg-[#F5F7FB] w-full rounded-md py-6 flex flex-col justify-center items-center text-center">
            <p
              className={`font-bold text-xl text-[${
                analyticsCards.color || '#6585E8'
              }]`}
            >
              {data?.free || 0}
            </p>
            <p className="text-[#202020] opacity-50 text-sm">
              {analyticsCards?.subCards[0].title}
            </p>
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center gap-4">
          {data?.length > 0 ? (
            data?.map(subCard => (
              <div className="bg-[#F5F7FB] w-full rounded-md py-6 flex flex-col justify-center items-center text-center">
                <p
                  className={`font-bold text-xl text-[${
                    analyticsCards.color || '#6585E8'
                  }]`}
                >
                  {subCard?.count || 0}
                </p>
                <p className="text-[#202020] opacity-50 text-sm capitalize">
                  {subCard?.name || subCard?.title}
                </p>
              </div>
            ))
          ) : (
            <div className="bg-[#F5F7FB] w-full rounded-md py-6 flex flex-col justify-center items-center text-center">
              <p
                className={`font-bold text-xl text-[${
                  analyticsCards.color || '#6585E8'
                }]`}
              >
                {0}
              </p>
              <p className="text-[#202020] opacity-50 text-sm capitalize">
                {'Free'}
              </p>
            </div>
          )}
        </div>
      )}
      {/* </div> */}
    </div>
  );
}

// const FilterTab = ({ filterType, setFilterType, type }) => {
//   console.log({ filterType, setFilterType, type });

//   let setValueFunction = selectedType => {
//     const value = filterType.find(
//       ele => ele.filter === selectedType && ele.type == type,
//     );
//     return value;
//   };
//   // let [value, setValue] = useState('all');
//   const handleChangeFilter = filter => {
//     const value = setValueFunction(filter);
//     setFilterType([{ ...filterType,  }]);
//     // props.setAnalyticsCards(
//     //   props.analyticsCards.map(analyticsCard =>
//     //     analyticsCard.id === props.id
//     //       ? {
//     //           ...analyticsCard,
//     //           filter: filter,
//     //         }
//     //       : analyticsCard,
//     //   ),
//     // );
//   };
//   // console.log({ value });
//   return (
//     <div className="bg-[#98A2B32E] text-xs rounded-md p-2 shadow-filter-tab-shadow flex items-center gap-1">
//       <span
//         className={`py-1 px-2 rounded-md font-medium cursor-pointer transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
//           setValueFunction('all') &&
//           'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
//         } `}
//         onClick={() => handleChangeFilter('all')}
//       >
//         All
//       </span>
//       <span
//         className={`py-1 px-2 rounded-md font-medium cursor-pointer  transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
//           setValueFunction('day') &&
//           'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
//         } `}
//         onClick={() => handleChangeFilter('day')}
//       >
//         Day
//       </span>
//       <span
//         className={`py-1 px-2 rounded-md font-medium cursor-pointer  transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
//           setValueFunction('week') &&
//           'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
//         } `}
//         onClick={() => handleChangeFilter('week')}
//       >
//         Week
//       </span>
//       <span
//         className={`py-1 px-2 rounded-md font-medium cursor-pointer  transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
//           setValueFunction('month') &&
//           'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
//         } `}
//         onClick={() => handleChangeFilter('month')}
//       >
//         Month
//       </span>
//     </div>
//   );
// };
const FilterTab = ({ filterType, setFilterType, type }) => {
  // console.log({ filterType, setFilterType, type });

  const setValueFunction = selectedType => {
    const value = filterType.find(
      ele => ele.filter === selectedType && ele.type === type,
    );
    return value ? value.filter : null;
  };
  const dispatch = useDispatch();

  const handleChangeFilter = filter => {
    const updatedFilterType = filterType.map(ele =>
      ele.type === type ? { ...ele, filter } : ele,
    );
    console.log({ filter, updatedFilterType }, 'asfasd');
    type === 'userCount' && dispatch(fetchUserCountAPI(filter));
    type === 'userCountPlans' && dispatch(fetchUserPlanAPI(filter));
    // type === "userCountPlans" && dispatch(fetchUserLocationAPI(filter));
    type === 'videoCount' && dispatch(fetchVideoCountAPI(filter));
    type === 'userDomainCount' && dispatch(fetchCustomdomainCountAPI(filter));
    // type === "userCountPlans" && dispatch(fetchVideoTopUsersAPI(filter));
    setFilterType(updatedFilterType);
  };

  return (
    <div className="bg-[#98A2B32E] text-xs rounded-md p-2 shadow-filter-tab-shadow flex items-center gap-1">
      <span
        className={`py-1 px-2 rounded-md font-medium cursor-pointer transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
          setValueFunction('all') === 'all' &&
          'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
        }`}
        onClick={() => handleChangeFilter('all')}
      >
        All
      </span>
      <span
        className={`py-1 px-2 rounded-md font-medium cursor-pointer  transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
          setValueFunction('day') === 'day' &&
          'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
        }`}
        onClick={() => handleChangeFilter('day')}
      >
        Day
      </span>
      <span
        className={`py-1 px-2 rounded-md font-medium cursor-pointer  transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
          setValueFunction('week') === 'week' &&
          'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
        }`}
        onClick={() => handleChangeFilter('week')}
      >
        Week
      </span>
      <span
        className={`py-1 px-2 rounded-md font-medium cursor-pointer  transition-all hover:shadow-filter-tab-selected-shadow hover:bg-white hover:text-[#6585E8] ${
          setValueFunction('month') === 'month' &&
          'shadow-filter-tab-selected-shadow bg-white text-[#6585E8]'
        }`}
        onClick={() => handleChangeFilter('month')}
      >
        Month
      </span>
    </div>
  );
};
