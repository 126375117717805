import React, { useState } from 'react';
import { Close } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Subscriptions } from 'assets/icons';
import { Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  postSubscriptionApi,
  updateSubscriptionAPI,
} from 'utils/@reduxjs/subscription/SubscriptionApi';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

export function SubscriptionCreateEditDialog({
  openModal,
  handleCloseModal,
  edit,
  setSubscriptions,
  subscription,
}) {
  const [subscriptionData, setSubscriptionData] = useState<any>(
    edit
      ? {
        id: subscription?._id,
        title: subscription?.name,
        price: subscription?.price,
        detail: subscription?.detail,
        featuresList: subscription?.featureLabels ?? [],
        mostPopular: subscription?.isMostPopular,
        videoLimit: subscription?.noOfVideos,
      }
      : {
        title: '',
        price: '',
        detail: '',
        featuresList: [],
        mostPopular: false,
        videoLimit: 0,
      },
  );
  const [feature, setFeature] = useState('');
  const handleAddFeature = () => {
    setSubscriptionData({
      ...subscriptionData,
      featuresList: [...subscriptionData?.featuresList, feature],
    });
    setFeature('');
  };
  const handleChange = e => {
    setSubscriptionData({
      ...subscriptionData,
      [e.target.name]: e.target.value,
    });
  };

  console.log('subscriptionData', subscriptionData);

  const dispatch = useDispatch();

  const handleRemoveFeature = (name: String) => {
    const removeItem = subscriptionData?.featuresList?.findIndex(
      (e: String) => e === name,
    );
    subscriptionData?.featuresList.splice(removeItem, 1);
    setSubscriptionData({
      ...subscriptionData,
      featuresList: [...subscriptionData?.featuresList],
    });
  };

  const handleSaveSubscription = () => {
    if (edit) {
      // setSubscriptions(subscriptions =>
      //   subscriptions.map(sub =>
      //     sub._id === subscription._id ? subscriptionData : sub,
      //   ),
      // );
      const data = {
        amount: subscriptionData.price,
        name: subscriptionData.title,
        noOfVideos: subscriptionData.videoLimit,
        isMostPopular: subscriptionData.mostPopular,
        featureLabels: subscriptionData.featuresList,

      };
      if (
        (subscriptionData.price || subscriptionData.price === 0) &&
        subscriptionData.title &&
        subscriptionData.videoLimit
      ) {
        dispatch(updateSubscriptionAPI(subscriptionData.id, data));
        handleCloseModal();
        console.log({ subscriptionData });
      } else {
        toast.error('Fields cannot be empty!');
      }
    } else {
      console.log({ subscriptionData });
      const data = {
        name: subscriptionData.title,
        amount: subscriptionData.price,
        noOfVideos: subscriptionData.videoLimit,
        feature: subscriptionData.featuresList,
        isMostPopular: subscriptionData.mostPopular,
        currency: 'usd',
        recurring: {
          interval: 'month',
          interval_count: 1,
        },
      };
      if (
        subscriptionData.price &&
        subscriptionData.title &&
        subscriptionData.videoLimit
      ) {
        dispatch(postSubscriptionApi(data));
        setSubscriptionData({
          ...subscriptionData,
          title: '',
          price: '',
          detail: '',
          featuresList: [],
          mostPopular: false,
          videoLimit: 0,
        });
        handleCloseModal();
        console.log({ subscriptionData });
      } else {
        toast.error('Fields cannot be empty!');
      }
    }
    // setSubscriptionData({
    //   id: Math.floor(Math.random() * 90 + 10),
    //   title: '',
    //   price: '',
    //   detail: '',
    //   featuresList: [''],
    //   mostPopular: false,
    //   videoLimit: 0,
    // });
  };

  console.log(subscriptionData, ":::");

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      classes={{
        paper:
          'w-full lg:!max-w-[40%] !max-w-[95%] !text-dark-blue !rounded-md px-6',
        container: '!h-screen !w-screen',
      }}
    >
      <DialogTitle className="!font-bold !px-0  !text-base flex justify-between items-center border-b border-extra-light-blue">
        <div className="flex justify-start items-center gap-4">
          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-light-purple">
            <Subscriptions />
          </div>
          {edit ? 'Edit Subscription' : 'Create New Subscription'}
        </div>
        <span className="cursor-pointer" onClick={handleCloseModal}>
          <Close className="!w-5 !h-5" />
        </span>
      </DialogTitle>
      <DialogContent className="!px-0 sm:px-5">
        <div className="bg-white rounded-xl w-full text-dark-blue font-bold text-sm !px-0 !py-4">
          <div className="flex justify-between items-center gap-4">
            <div className="w-2/4">
              <label htmlFor="title">Plan Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={subscriptionData?.title}
                onChange={handleChange}
                placeholder="Add Plan Title"
                className="bg-light-grey w-full mt-2 py-3 px-4 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
              />
            </div>
            <div className="w-1/4">
              <label htmlFor="videoLimit">Video Limit</label>
              <div className="relative">
                <input
                  type="number"
                  min={0}
                  name="videoLimit"
                  value={subscriptionData?.videoLimit}
                  onChange={handleChange}
                  id="videoLimit"
                  placeholder="Video Limit"
                  className="bg-light-grey w-full mt-2 py-3 px-4 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
                />
              </div>
            </div>
            <div className="w-1/4">
              <label htmlFor="price">Price/mo</label>
              <div className="relative">
                <span className="absolute top-[21px] left-2 sm:left-4">$</span>
                <input
                  type="number"
                  min={0}
                  name="price"
                  value={subscriptionData?.price}
                  onChange={handleChange}
                  id="price"
                  placeholder="250"
                  className="bg-light-grey w-full mt-2 py-3 pr-4 pl-4 sm:pl-9 text-sm border font-normal border-extra-light-blue rounded-md outline-none"
                />
              </div>
            </div>
          </div>
          <div className="relative mt-4">
            <label htmlFor="features">Features</label>
            <input
              type="text"
              name="search"
              id="features"
              placeholder="Add Points"
              value={feature}
              onChange={e => setFeature(e.target.value)}
              className="bg-light-grey w-full mt-2 py-3 pl-4 pr-32  text-sm border font-normal border-extra-light-blue rounded-md outline-none"
            />
            <div
              className="absolute  bg-[#C131DE] text-white text-xs py-2 px-4 font-bold rounded-md cursor-pointer w-fit right-2 top-9"
              onClick={() => (feature !== '' ? handleAddFeature() : null)}
            >
              Add Feature
            </div>
          </div>
          {subscriptionData?.featuresList?.filter(e => e !== '').length > 0 && (
            <div className="py-2">
              <label className="text-dark-blue font-bold">
                Added features :
              </label>
              <div className="flex items-center justify-start mt-2 gap-4 flex-wrap">
                {subscriptionData?.featuresList
                  ?.filter(e => e !== '')
                  .map(
                    (name: String) =>
                      name !== '' && (
                        <div className="bg-light-grey border border-extra-light-blue rounded-3xl px-3 flex items-center gap-2">
                          <span>{name}</span>
                          <span
                            className="cursor-pointer"
                            onClick={() => handleRemoveFeature(name)}
                          >
                            <CloseIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </span>
                        </div>
                      ),
                  )}
              </div>
            </div>
          )}
          <div>
            <FormControlLabel
              sx={{
                '.MuiTypography-root': {
                  color: '#262C4C',
                  fontWeight: 'bold',
                  fontSize: '14px',
                },
              }}
              control={
                <Checkbox
                  // {...label}
                  sx={{
                    color: '#DDEBFB',
                    '&.Mui-checked': {
                      color: '#6585E8',
                    },
                  }}
                  onChange={e => {
                    setSubscriptionData({
                      ...subscriptionData,
                      mostPopular: e.target.checked,
                    });
                  }}
                  checked={subscriptionData.mostPopular}
                />
              }
              label="Mark as Most Popular"
            />
          </div>
          <div className="flex justify-center items-center gap-4 mt-2">
            <button
              className="border border-light-purple text-light-purple w-32 mt-1 rounded-md px-3 py-3 text-xs "
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              className="bg-light-purple w-32 mt-1 text-white rounded-md px-3 py-3 text-xs "
              onClick={handleSaveSubscription}
            >
              Save
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
