/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { LoginPage } from './pages/Login/Loadable';
import { Dashboard } from './pages/Dashboard';
import { Layout } from './Layout';
import { Users } from './pages/Users';
import { UsersDetails } from './pages/UsersDetails';
import { Subscriptions } from './pages/Subscriptions';
import { FAQs } from './pages/FAQs';
import { Settings } from './pages/Settings';
import { PrivateWrapper } from 'utils/privateWrapper';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        // titleTemplate="%s -O"
        defaultTitle="One clip"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="One Clip application" />
      </Helmet>

      <Routes>
        <Route
          path="/settings"
          element={
            <PrivateWrapper>
              <Layout>
                <Settings />
              </Layout>
            </PrivateWrapper>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <PrivateWrapper>
              <Layout>
                <Subscriptions />
              </Layout>
            </PrivateWrapper>
          }
        />
        <Route
          path="/faqs"
          element={
            <PrivateWrapper>
              <Layout>
                <FAQs />
              </Layout>
            </PrivateWrapper>
          }
        />
        <Route
          path="/users/:id"
          element={
            <PrivateWrapper>
              <Layout>
                <UsersDetails />
              </Layout>
            </PrivateWrapper>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateWrapper>
              <Layout>
                <Users />
              </Layout>
            </PrivateWrapper>
          }
        />
        <Route
          path="/"
          element={
            <PrivateWrapper>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateWrapper>
          }
        />
        {/* <Route path="/pricing-plans" element={<PricingPlans />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/register" element={<Register />} /> */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
