import { createSlice } from "@reduxjs/toolkit";

type dashboardState = { userCount: any, userCountPlans: any, userCountLocation: any, videoCount: any, videoTopUsers: any, customdomainCount: any }
const initialState: dashboardState = {
    userCount: { isLoading: true },
    userCountPlans: { isLoading: true },
    userCountLocation: { isLoading: true },
    videoCount: { isLoading: true },
    videoTopUsers: { isLoading: true },
    customdomainCount: { isLoading: true }

}


export const campaignsSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        getUserCount: (state, action) => {
            state.userCount = { ...state.userCount, ...action.payload, isLoading: false };
        },
        getUserCountPlans: (state, action) => {
            state.userCountPlans = { ...state.userCountPlans, ...action.payload, isLoading: false };
        },
        getUserCountLocation: (state, action) => {
            state.userCountLocation = { ...state.userCountLocation, ...action.payload, isLoading: false };
        },
        getVideoCount: (state, action) => {
            state.videoCount = { ...state.videoCount, ...action.payload, isLoading: false };
        },
        getVideoTopUsers: (state, action) => {
            state.videoTopUsers = { ...state.videoTopUsers, ...action.payload, isLoading: false };
        },
        getCustomdomainCount: (state, action) => {
            state.customdomainCount = { ...state.customdomainCount, ...action.payload, isLoading: false };
        },

    },
});

export default campaignsSlice.reducer;

export const { reducer: dashboardReducers, actions: dashboardActions } = campaignsSlice;

