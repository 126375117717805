import { createSlice } from "@reduxjs/toolkit";

type usersState = { users: any, singleUser: any, transactions: {} }
const initialState: usersState = {
    users: {},
    singleUser: {},
    transactions: {}

}


export const usersSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUsers: (state, action) => {
            state.users = { ...state.users, ...action.payload };
        },
        getSingleUser: (state, action) => {
            state.singleUser = { ...state.singleUser, ...action.payload };
        },
        getTransactionsUser: (state, action) => {
            state.transactions = { ...state.transactions, ...action.payload };
        }
    },
});

export default usersSlice.reducer;

export const { reducer: usersReducers, actions: usersActions } = usersSlice;

